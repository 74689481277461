import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import * as d3 from 'd3';
import styles from '../FourHandleNode.module.css';
import {
  DIAGRAM_DRAWING_COLORS,
  NodeStrokeTypes,
} from '../../../../../components/DrawingPalettes/constants';
import { DefaultBoxNodeDisplay } from '../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddBox/constants';
import { updateNodeDisplayHelper } from '../../../utils';
import { BOX_COLOR_CLASS_NAMES } from './boxConstants';

type ClassNamesForBoxStyling = { [key: string]: boolean };

export const generateClassNamesForBoxStyling = ({
  strokeType,
  strokeColorName,
}: DiagramBoxDisplay): ClassNamesForBoxStyling => {
  const isDashed = strokeType === NodeStrokeTypes.dashed;
  const colorIndex = DIAGRAM_DRAWING_COLORS.findIndex(
    (color) => color === strokeColorName,
  );
  const isColoredBox = colorIndex != -1;
  const classNamesList: ClassNamesForBoxStyling = {};

  if (isDashed) {
    classNamesList[styles.dashedStroke] = true;
  }

  if (isColoredBox) {
    classNamesList[BOX_COLOR_CLASS_NAMES[colorIndex]] = true;
  }

  return classNamesList;
};

export const updateBoxNode = ({
  display,
  nodeUid,
  updateNode,
  component,
}: UpdateDiagramNodeProps & {
  updateNode: MutationTrigger<
    MutationDefinition<UpdateDiagramNodeProps, BaseQueryFn, string, DiagramNode>
  >;
  component: d3.Selection<HTMLDivElement, unknown, null, undefined>;
}): void =>
  updateNodeDisplayHelper({
    component,
    display: {
      drawingType: DefaultBoxNodeDisplay.drawingType,
      ...display,
    },
    nodeUid,
    updateNode,
  });
