import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { iconPickerSelectors } from '../../../../redux/iconPicker/slice';
import { IconPickerIcons } from '.';

export type IconPickerIconsContainerOwnProps = {
  tabTitle: string;
};

const mapStateToProps = (
  state: RootState,
  ownProps: IconPickerIconsContainerOwnProps,
) => ({
  icons: iconPickerSelectors.getIconsForTab(state, ownProps.tabTitle),
});

export type IconPickerIconsProps = ReturnType<typeof mapStateToProps>;

export const IconPickerIconsContainer =
  connect(mapStateToProps)(IconPickerIcons);
