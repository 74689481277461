import { connect } from 'react-redux';
import {
  iconPickerSelectors,
  setSelectedIcon,
} from '../../../../redux/iconPicker/slice';
import { RootState } from '../../../../redux/store';
import { IconPickerIcon } from '.';

export type IconPickerIconContainerOwnProps = {
  icon: IconPickerIcon;
};

const mapDispatchToProps = {
  setSelectedIcon,
};

const mapStateToProps = (
  state: RootState,
  ownProps: IconPickerIconContainerOwnProps,
) => {
  const selectedIcon = iconPickerSelectors.getSelectedIcon(state);
  const isSelected = ownProps.icon.name === selectedIcon;

  return {
    icon: ownProps.icon,
    isSelected,
  };
};

export type IconPickerIconProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const IconPickerIconContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IconPickerIcon);
