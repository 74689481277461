import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextarea,
  ControlledTextfield,
  Modal,
} from '../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import { MaxValues } from '../../../../../../../../validation';
import { createOsOptions } from '../../utils';
import { CpuInput } from '../CpuInput';
import { MemoryInput } from '../MemoryInput';
import formStyles from '../../../../../EditFormStyles.module.css';
import { FORM_SECTION_CLASSES } from '../../../constants';
import { IconPickerContainer } from '../../../../../../../../components/IconPicker/container';
import {
  DEFAULT_ICON_PICKER_ICONS,
  DEFAULT_TAB_ORDER,
} from '../../../../../../../../constants/iconPicker';
import { getSelectedIcon } from './utils';
import styles from './GeneralForm.module.css';
import { GeneralFormProps } from './container';

export function GeneralForm({
  operatingSystems,
  nodeData,
  selectedIcon,
}: GeneralFormProps): ReactElement {
  const { control, errors, watch } = useFormContext();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [largeVmWarning, setLargeVmWarning] = useState<string>('');
  const [localSelectedIcon, setLocalSelectedIcon] = useState<string>(
    nodeData?.display?.icon || '',
  );

  const handleUpdateIcon = async () => {
    selectedIcon && setLocalSelectedIcon(selectedIcon);
    control.setValue('icon', selectedIcon, {
      shouldDirty: true,
    });
    setShowModal(false);
  };

  const watchMemoryGb = watch('memoryGb');
  const watchCpuQty = watch('cpuQty');

  const checkLargeVmValues = useCallback(() => {
    if (
      watchMemoryGb > MaxValues.VirtualMachineMemoryGbRecommended &&
      watchCpuQty > MaxValues.VirtualMachineCpuQtyRecommended
    ) {
      setLargeVmWarning(t('virtualMachines.edit.memoryAndCpu'));
    } else if (watchMemoryGb > MaxValues.VirtualMachineMemoryGbRecommended) {
      setLargeVmWarning(t('virtualMachines.edit.memoryMb'));
    } else if (watchCpuQty > MaxValues.VirtualMachineCpuQtyRecommended) {
      setLargeVmWarning(t('virtualMachines.edit.cpuQty'));
    } else {
      setLargeVmWarning('');
    }
  }, [t, watchCpuQty, watchMemoryGb]);

  useEffect(() => {
    checkLargeVmValues();
  }, [checkLargeVmValues]);

  return (
    <>
      {largeVmWarning.length > 0 && (
        <Alert data-testid="large-vm-warning" colour="warning">
          {t('virtualMachines.edit.largeVmWarningPt1')} {largeVmWarning}
          {t('virtualMachines.edit.largeVmWarningPt2')}
        </Alert>
      )}
      <div className={classnames(styles.root, formStyles.sectionPadding)}>
        <div className={FORM_SECTION_CLASSES}>
          <ControlledTextfield
            control={control}
            error={errors?.name?.message}
            label={t('virtualMachines.edit.name')}
            name="name"
            required={true}
            testId="edit-vm-name"
            id={`vm-edit-general-name-input`}
          />

          <ControlledSelect
            control={control}
            label={t('virtualMachines.edit.osIcon')}
            name="osFamily"
            options={createOsOptions(operatingSystems)}
            id={`vm-edit-general-os-input`}
          />

          <ControlledTextarea
            control={control}
            error={errors?.description?.message}
            label={t('virtualMachines.edit.description')}
            name="description"
            rows={4}
            required={true}
            testId="edit-vm-description"
            id={`vm-edit-general-description-input`}
          />
          <ControlledCheckbox
            className={styles.checkbox}
            control={control}
            id="nestedHypervisor"
            label={t('virtualMachines.edit.nestedHypervisor')}
            testId="edit-vm-nested-hypervisor"
          />
        </div>
        <div className={FORM_SECTION_CLASSES}>
          <CpuInput />
          <MemoryInput />

          {nodeData && (
            <div className={styles.iconGroup}>
              <Controller
                name="icon"
                defaultValue={selectedIcon}
                control={control}
                render={() => (
                  <div id="icon" className={styles.vmIcon}>
                    {getSelectedIcon(localSelectedIcon)}
                  </div>
                )}
              />
              <div className={styles.buttonWrapper}>
                <PrimaryButton
                  onClick={() => setShowModal(true)}
                  colour="primary"
                  name={t('virtualMachines.edit.changeIcon')}
                >
                  {t('virtualMachines.edit.changeIcon')}
                </PrimaryButton>
              </div>
            </div>
          )}
          <Modal
            clickScreenToClose={true}
            show={showModal}
            onClose={() => setShowModal(false)}
            title={t('virtualMachines.edit.changeIcon')}
            headerAlignment="center"
            size="large"
          >
            <IconPickerContainer
              onCancelButtonClick={() => setShowModal(false)}
              onPrimaryButtonClick={handleUpdateIcon}
              showButtons={true}
              existingSelectedIcon={localSelectedIcon}
              availableIcons={DEFAULT_ICON_PICKER_ICONS}
              tabOrder={DEFAULT_TAB_ORDER}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
