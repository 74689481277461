import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import { PrimaryButton } from '../../../Buttons/PrimaryButton';
import { IconPickerButtonsProps } from './container';

export function IconPickerButtons({
  onCancelButtonClick,
  onPrimaryButtonClick,
  initalSelectedIcon,
  selectedIcon,
  showButtons,
}: IconPickerButtonsProps): ReactElement {
  const { t } = useTranslation();

  if (!showButtons) return <></>;

  const handleOnPrimaryButtonClick = () => {
    onPrimaryButtonClick && onPrimaryButtonClick(selectedIcon || '');
  };

  return (
    <div className="flex flex-center dbl-margin-top">
      <Button colour="ghost" onClick={onCancelButtonClick}>
        {t('buttons.cancel')}
      </Button>
      <PrimaryButton
        onClick={handleOnPrimaryButtonClick}
        disabled={selectedIcon === initalSelectedIcon}
      >
        {t('buttons.use')}
      </PrimaryButton>
    </div>
  );
}
