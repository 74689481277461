import React, { ReactElement } from 'react';
import { IconPickerContainer } from '../../../../../../components/IconPicker/container';

export type CommonIconEditIconModalIconPickerProps = {
  tabOrder: Array<string>;
  availableIcons: IconPickerIcons;
  existingIcon: string;
};

export const CommonIconEditIconModalIconPicker = ({
  tabOrder,
  availableIcons,
  existingIcon,
}: CommonIconEditIconModalIconPickerProps): ReactElement => (
  <div className="base-padding-top">
    <IconPickerContainer
      availableIcons={availableIcons}
      tabOrder={tabOrder}
      existingSelectedIcon={existingIcon}
    />
  </div>
);
