import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useEffect } from 'react';

export type UseInititialiseIconPicker = {
  availableIcons: IconPickerIcons;
  setAvailableIcons: ActionCreatorWithPayload<IconPickerIcons, string>;
  setTabOrder: ActionCreatorWithPayload<string[], string>;
  tabOrder: Array<string>;
  reset: ActionCreatorWithoutPayload<string>;
  setShowButtons: ActionCreatorWithPayload<boolean, string>;
  showButtons: boolean;
  existingSelectedIcon?: string;
  setInitialSelectedIcon: ActionCreatorWithPayload<string, string>;
  setSelectedIcon: ActionCreatorWithPayload<string | undefined, string>;
};

export const useInititialiseIconPicker = ({
  availableIcons,
  setAvailableIcons,
  setTabOrder,
  tabOrder,
  reset,
  setShowButtons,
  showButtons,
  existingSelectedIcon,
  setInitialSelectedIcon,
  setSelectedIcon,
}: UseInititialiseIconPicker): void => {
  useEffect(() => {
    setAvailableIcons(availableIcons);
    setTabOrder(tabOrder);
    setShowButtons(showButtons);
    existingSelectedIcon && setInitialSelectedIcon(existingSelectedIcon);
    existingSelectedIcon && setSelectedIcon(existingSelectedIcon);

    return () => {
      reset();
    };
  }, [
    availableIcons,
    existingSelectedIcon,
    reset,
    setAvailableIcons,
    setInitialSelectedIcon,
    setSelectedIcon,
    setShowButtons,
    setTabOrder,
    showButtons,
    tabOrder,
  ]);
};
