import { connect } from 'react-redux';
import { iconPickerSelectors } from '../../../../redux/iconPicker/slice';
import { RootState } from '../../../../redux/store';
import { IconPickerButtons } from '.';

export type IconPickerButtonsContainerOwnProps = {
  onCancelButtonClick?: VoidFunction;
  onPrimaryButtonClick?: (selectedIconName: string) => void;
};

const mapStateToProps = (
  state: RootState,
  ownProps: IconPickerButtonsContainerOwnProps,
) => ({
  initalSelectedIcon: iconPickerSelectors.getInititalSelectedIcon(state),
  selectedIcon: iconPickerSelectors.getSelectedIcon(state),
  showButtons: iconPickerSelectors.getShowButtons(state),
  ...ownProps,
});

export type IconPickerButtonsProps = ReturnType<typeof mapStateToProps>;

export const IconPickerButtonsContainer =
  connect(mapStateToProps)(IconPickerButtons);
