import * as d3 from 'd3';
import {
  LOGICAL_CONNECTION_MAX_LIMIT,
  NODE_IS_DRAGGING_KEY,
  NODE_IS_DRAGGING_VALUES,
  TEMP_CONNECTION_GUIDE_LINE_STROKE_DASH_ARRAY,
  TEMP_CONNECTION_GUIDE_LINE_STROKE_WIDTH,
} from '../../../constants';
import { logicalConnectionLineDefaults } from '../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';

type UpdateNodeHelper = {
  updateNode: RTKMutation<UpdateDiagramNodeProps, UpdateDiagramNodeProps>;
  display?: DiagramDisplay;
  uid: string;
  x1: number;
  y1: number;
  component: d3.Selection<HTMLDivElement, unknown, null, undefined>;
};

export const updateNodeHelper = ({
  updateNode,
  display,
  uid,
  x1,
  y1,
  component,
}: UpdateNodeHelper): void => {
  if (component.attr(NODE_IS_DRAGGING_KEY) === NODE_IS_DRAGGING_VALUES.true) {
    updateNode({
      display: {
        hidden: display?.hidden,
        icon: display?.icon,
        x1,
        y1,
      },
      nodeUid: uid,
    });
    component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.false);
  }
};

type UpdateTempGuideLineFromAndToAttributes = {
  from: string;
  to: string;
  tempGuideLine: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>;
};

export const updateTempGuideLineFromAndToAttributes = ({
  from,
  to,
  tempGuideLine,
}: UpdateTempGuideLineFromAndToAttributes): void => {
  tempGuideLine.attr('data-connection-from', from);
  tempGuideLine.attr('data-connection-to', to);
};

export const removeNodeCanvasMouseMoveListener = (
  nodesCanvas: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
): void => {
  nodesCanvas.on('mousemove', null);
};

export const setNodeCanvasCursorStyle = (
  nodesCanvas: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
  value: string,
): void => {
  nodesCanvas.attr('style', `cursor: ${value}`);
};

type CreateLogicalConnection = {
  isDrawingLogicalConnectionFrom: string;
  isDrawingLogicalConnectionTo: string;
  createConnection: RTKMutation<CreateConnectionPayload, DiagramConnection>;
  topologyUid: string;
};

export const createLogicalConnection = async ({
  isDrawingLogicalConnectionFrom,
  isDrawingLogicalConnectionTo,
  createConnection,
  topologyUid,
}: CreateLogicalConnection): Promise<string | void> => {
  const color = logicalConnectionLineDefaults.strokeDarkThemeColor;
  const strokeType = logicalConnectionLineDefaults.strokeType;
  const drawingType = logicalConnectionLineDefaults.drawingType;
  const type = 'LOGICAL';

  try {
    const { data: DiagramConnection } = await createConnection({
      display: {
        connection: {
          color,
          order: calculateNewLogicalConnectionOrderValue({
            from: isDrawingLogicalConnectionFrom,
            to: isDrawingLogicalConnectionTo,
          }),
          strokeType,
        },
        drawingType,
      },
      nodes: {
        from: {
          uid: isDrawingLogicalConnectionFrom,
        },
        to: {
          uid: isDrawingLogicalConnectionTo,
        },
      },
      topology: { uid: topologyUid },
      type,
    });

    return DiagramConnection.uid;
  } catch {
    return undefined;
  }
};

export const resetTempGuideLine = (
  tempGuideLine: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
): void => {
  tempGuideLine.attr('data-connection-from', '');
  tempGuideLine.attr('data-connection-to', '');
  tempGuideLine.attr('x1', 0);
  tempGuideLine.attr('y1', 0);
  tempGuideLine.attr('x2', 0);
  tempGuideLine.attr('y2', 0);
};

type UpdateLogicalConnectionTotals = {
  from: string;
  to: string;
  isIncremeting: boolean;
};

export const updateLogicalConnectionTotals = ({
  isIncremeting,
  from,
  to,
}: UpdateLogicalConnectionTotals): void => {
  d3.selectAll<HTMLDivElement, unknown>(
    `line[data-connection-from-to="${from}-${to}"]`,
  ).each(function () {
    const currentTotal = this.getAttribute('data-connection-total');

    const newValue = isIncremeting
      ? Number(currentTotal) + 1
      : Number(currentTotal) - 1;
    this.setAttribute('data-connection-total', newValue.toString());
  });
};

export const makeTempGuideLineFollowMouse = (
  mouseX: number,
  mouseY: number,
  tempGuideLine: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
): void => {
  tempGuideLine.attr('x2', mouseX);
  tempGuideLine.attr('y2', mouseY - 50);
  tempGuideLine.attr('stroke-width', TEMP_CONNECTION_GUIDE_LINE_STROKE_WIDTH);
  tempGuideLine.attr(
    'stroke-dasharray',
    TEMP_CONNECTION_GUIDE_LINE_STROKE_DASH_ARRAY,
  );
};

export type HandleMouseDownOnNodesCanvas = {
  isDrawingLogicalConnectionFrom?: string;
  isCreatingLogicalConnection?: boolean;
  redrawNodeConnections: (uid: string) => void;
  resetLogicalConnections: VoidFunction;
  uid?: string;
};

export const handleMouseDownOnNodesCanvas = ({
  isDrawingLogicalConnectionFrom,
  isCreatingLogicalConnection,
  redrawNodeConnections,
  resetLogicalConnections,
  uid,
}: HandleMouseDownOnNodesCanvas): void => {
  if (isDrawingLogicalConnectionFrom && !isCreatingLogicalConnection) {
    resetLogicalConnections();
    redrawNodeConnections(isDrawingLogicalConnectionFrom);
    uid && redrawNodeConnections(uid);
  }
};

type CalculateDistanceFromCenterOfNodeForConnection = {
  order: number;
  total: number;
  padding: number;
};
export const calculateDistanceFromCenterOfNodeForConnection = ({
  order,
  total,
  padding,
}: CalculateDistanceFromCenterOfNodeForConnection): number => {
  const midPoint = total / 2;
  if (order > midPoint) {
    const paddingFactor = order - midPoint;

    return paddingFactor * padding - padding / 2;
  } else {
    const paddingFactor = midPoint - order;

    return -(paddingFactor * padding + padding / 2);
  }
};

type CalculateAngleOfLine = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const calculateAngleOfLineInDegrees = ({
  x1,
  x2,
  y1,
  y2,
}: CalculateAngleOfLine): number => {
  const result = calculateAngleOfLineInRadians({ x1, x2, y1, y2 });

  return convertAngleFromRadiansToDegrees(result) - 90;
};

export const calculateAngleOfLineInRadians = ({
  x1,
  x2,
  y1,
  y2,
}: CalculateAngleOfLine): number => {
  return Math.atan2(y2 - y1, x2 - x1);
};

export const convertAngleFromRadiansToDegrees = (
  angleInRadians: number,
): number => {
  return (angleInRadians * 180) / Math.PI;
};

type CalculateMultipleConnection = {
  radius: number;
  angle: number;
};

export const calculateMultipleConnectionX = ({
  radius,
  angle,
}: CalculateMultipleConnection): number => {
  const newAngle = ((angle - 90) * Math.PI) / 180;

  return Math.cos(newAngle) * radius;
};

export const calculateMultipleConnectionY = ({
  radius,
  angle,
}: CalculateMultipleConnection): number => {
  const newAngle = ((angle - 90) * Math.PI) / 180;

  return Math.sin(newAngle) * radius;
};

type ReDrawMulitpleConnections = {
  yMid: number;
  xMid: number;
  otherYMid: number;
  otherXMid: number;
  padding: number;
  selectedConnection: SVGLineElement;
};

export const reDrawMulitpleConnections = ({
  yMid,
  xMid,
  otherXMid,
  otherYMid,
  padding,
  selectedConnection,
}: ReDrawMulitpleConnections): void => {
  const angle = calculateAngleOfLineInDegrees({
    x1: xMid,
    x2: otherXMid,
    y1: yMid,
    y2: otherYMid,
  });

  const radius = calculateDistanceFromCenterOfNodeForConnection({
    order: Number(
      selectedConnection.getAttribute('data-connection-derived-order'),
    ),
    padding: padding,
    total: Number(selectedConnection.getAttribute('data-connection-total')),
  });

  selectedConnection.setAttribute(
    'x1',
    (
      xMid +
      calculateMultipleConnectionX({
        angle: angle - 90,
        radius,
      })
    ).toString(),
  );

  selectedConnection.setAttribute(
    'y1',
    (
      yMid +
      calculateMultipleConnectionY({
        angle: angle - 90,
        radius,
      })
    ).toString(),
  );

  selectedConnection.setAttribute(
    'x2',
    (
      otherXMid +
      calculateMultipleConnectionX({
        angle: angle - 90,
        radius,
      })
    ).toString(),
  );

  selectedConnection.setAttribute(
    'y2',
    (
      otherYMid +
      calculateMultipleConnectionY({
        angle: angle - 90,
        radius,
      })
    ).toString(),
  );
};

export const sumMultipleConnectionsBetweenNodes = (
  diagramResponseConnections: DiagramConnection[],
  connection: DiagramConnection,
): number => {
  const currentConnectionNodesConnected = [
    connection.nodes.from.uid,
    connection.nodes.to.uid,
  ];

  const result = diagramResponseConnections.filter((connection) => {
    const nodesConnected = [connection.nodes.from.uid, connection.nodes.to.uid];

    return (
      currentConnectionNodesConnected.filter((uid) =>
        nodesConnected.includes(uid),
      ).length === 2
    );
  });

  return result.length / 2;
};

type CalculateNewConnectionOrderValue = {
  to: string;
  from: string;
};

export const calculateNewLogicalConnectionOrderValue = ({
  to,
  from,
}: CalculateNewConnectionOrderValue): number => {
  const selection = d3
    .selectAll<HTMLDivElement, unknown>(
      `[data-connection-from-to="${from}-${to}"]`,
    )
    .nodes();

  const selectionReversed = d3
    .selectAll<HTMLDivElement, unknown>(
      `[data-connection-from-to="${to}-${from}"]`,
    )
    .nodes();

  const mulitpleConnections = [...selection, ...selectionReversed];

  return mulitpleConnections.reduce(function (acc, connection) {
    if (Number(connection.getAttribute('data-connection-order')) >= acc) {
      acc = Number(connection.getAttribute('data-connection-order')) + 1;
    }
    return acc;
  }, 1);
};

type CalculateNodeMidPoints = {
  nodeBounds: DOMRect;
  headerHeight: number;
};

export const calculateNodeMidPoint = ({
  nodeBounds,
  headerHeight,
}: CalculateNodeMidPoints): { x: number; y: number } => ({
  x: nodeBounds.x + nodeBounds.width / 2,
  y: nodeBounds.y + nodeBounds.height / 2 - headerHeight,
});

type DetermineFromAndToAttributesForNewConnection = {
  isDrawingLogicalConnectionFrom: string;
  isDrawingLogicalConnectionTo: string;
  toNodes: Array<SVGElement>;
  fromNodes: Array<SVGElement>;
};

export const determineFromAndToAttributesForNewConnection = ({
  isDrawingLogicalConnectionFrom,
  isDrawingLogicalConnectionTo,
  toNodes,
  fromNodes,
}: DetermineFromAndToAttributesForNewConnection): {
  fromAttrToUseForNewConnection: string;
  toAttrToUseForNewConnection: string;
} => {
  const combinedNodes = [...toNodes, ...fromNodes];

  let fromAttrToUseForNewConnection = isDrawingLogicalConnectionFrom;
  let toAttrToUseForNewConnection = isDrawingLogicalConnectionTo;

  if (combinedNodes.length > 0) {
    fromAttrToUseForNewConnection = combinedNodes[0].getAttribute(
      'data-connection-from',
    )!;

    toAttrToUseForNewConnection =
      combinedNodes[0].getAttribute('data-connection-to')!;
  }

  return {
    fromAttrToUseForNewConnection,
    toAttrToUseForNewConnection,
  };
};

type IsLogicalConnectionLimitReached = {
  toNodes: number;
  fromNodes: number;
};

export const isLogicalConnectionLimitReached = ({
  toNodes,
  fromNodes,
}: IsLogicalConnectionLimitReached): boolean =>
  (toNodes + fromNodes) / 2 >= LOGICAL_CONNECTION_MAX_LIMIT;
