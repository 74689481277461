import React, { ReactElement, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DEFAULT_ICON_PICKER_ICONS,
  DEFAULT_TAB_ORDER,
} from '../../../../../../constants/iconPicker';
import {
  ControlledCheckbox,
  ControlledTextfield,
  Modal,
  ReadonlyField,
} from '../../../../../../components';
import formStyles from '../../../EditFormStyles.module.css';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { getSelectedIcon } from '../../../components/VirtualMachineEntities/Edit/components/GeneralForm/utils';
import { FORM_SECTION_CLASSES } from '../../../components/VirtualMachineEntities/constants';
import { IconPickerContainer } from '../../../../../../components/IconPicker/container';
import styles from './FormGeneral.module.css';
import { FormGeneralProps } from './FormGeneralContainer';

export function FormGeneral({
  hardware,
  nodeData,
  isModal,
  selectedIcon,
}: FormGeneralProps): ReactElement {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [localSelectedIcon, setLocalSelectedIcon] = useState<string>(
    nodeData?.display?.icon || '',
  );

  const handleUpdateIcon = async () => {
    selectedIcon && setLocalSelectedIcon(selectedIcon);
    control.setValue('icon', selectedIcon, {
      shouldDirty: true,
    });
    setShowModal(false);
  };

  return (
    <div className={classNames('row', formStyles.sectionPadding)}>
      <div className={FORM_SECTION_CLASSES}>
        <ReadonlyField
          label={t('hardware.edit.inventoryNameLabel')}
          value={hardware.inventoryHardwareItem.name}
        />
        <ControlledTextfield
          label={t('hardware.edit.nameLabel')}
          name="name"
          control={control}
          horizontal={false}
          required={true}
          error={errors?.name?.message}
          className="dbl-margin-bottom"
        />
        <div className="base-margin-top base-margin-bottom">
          <ControlledCheckbox
            control={control}
            label={t('hardware.edit.enablePowerControl')}
            defaultValue={hardware.powerControlEnabled}
            disabled={!hardware.inventoryHardwareItem.powerControlAvailable}
            id="powerControlEnabled"
          />
        </div>
        <div className="base-margin-top base-margin-bottom">
          <ControlledCheckbox
            control={control}
            defaultValue={hardware.hardwareConsoleEnabled}
            disabled={!hardware.inventoryHardwareItem.hardwareConsoleAvailable}
            id="hardwareConsoleEnabled"
            label={t('hardware.edit.enableHardwareConsole')}
          />
        </div>
        <div>
          <ControlledCheckbox
            control={control}
            id="hiddenFromSession"
            label={t('hardware.edit.hiddenFromSession')}
            testId="edit-hardware-hiddenFromSession-checkbox"
          />
        </div>
      </div>
      <div className={FORM_SECTION_CLASSES}>
        <div className={styles.iconGroup}>
          <Controller
            name="icon"
            defaultValue={selectedIcon}
            control={control}
            render={() => (
              <div id="icon" className={styles.vmIcon}>
                {getSelectedIcon(localSelectedIcon)}
              </div>
            )}
          />
          <PrimaryButton
            className={styles.changeIconButton}
            onClick={() => setShowModal(true)}
            colour="primary"
            name={t('virtualMachines.edit.changeIcon')}
          >
            {t('virtualMachines.edit.changeIcon')}
          </PrimaryButton>
        </div>
        <Modal
          size="large"
          clickScreenToClose={true}
          show={showModal}
          onClose={() => setShowModal(false)}
          title={t('virtualMachines.edit.changeIcon')}
          headerAlignment="center"
          className={isModal ? styles.iconPickerInModal : ''}
        >
          <IconPickerContainer
            onCancelButtonClick={() => setShowModal(false)}
            onPrimaryButtonClick={handleUpdateIcon}
            showButtons={true}
            existingSelectedIcon={localSelectedIcon}
            availableIcons={DEFAULT_ICON_PICKER_ICONS}
            tabOrder={DEFAULT_TAB_ORDER}
          />
        </Modal>
      </div>
    </div>
  );
}
