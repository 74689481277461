import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

export type HandleOnHWNodeClick = {
  expired: boolean;
  setLeftPanelType: ActionCreatorWithOptionalPayload<SessionPanel | undefined>;
  setLeftPanelDeviceUid: ActionCreatorWithOptionalPayload<string | undefined>;
  nodeUid: string;
};

export const handleOnHWNodeClick = ({
  expired,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  nodeUid,
}: HandleOnHWNodeClick): void => {
  if (!expired) {
    setLeftPanelType('hardware');
    setLeftPanelDeviceUid(nodeUid);
  }
};

export type HandleOnHWEditClick = {
  isHardwareNodeInDiagramView: boolean;
  assetUid?: string;
  setDeviceIdToConfigureInDiagram: ActionCreatorWithOptionalPayload<
    string | undefined
  >;
};

export const handleOnHWEditClick = ({
  isHardwareNodeInDiagramView,
  assetUid,
  setDeviceIdToConfigureInDiagram,
}: HandleOnHWEditClick): void => {
  if (isHardwareNodeInDiagramView) {
    setDeviceIdToConfigureInDiagram(assetUid);
  }
};

type DetermineShouldRenderHwNodeActionButtons = {
  isHardwareNodeInDiagramView: boolean;
  isAddingLogicalConnection: boolean;
};

export const determineShouldRenderHwNodeActionButtons = ({
  isHardwareNodeInDiagramView,
  isAddingLogicalConnection,
}: DetermineShouldRenderHwNodeActionButtons): boolean => {
  if (isHardwareNodeInDiagramView) {
    return !isAddingLogicalConnection;
  }
  return false;
};
