import React, { MouseEvent, ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import classNames from 'classnames';
import styles from './index.module.css';

export type NodeEditButtonProps = {
  name: string;
  onClick: VoidFunction;
  isCenterAligned?: boolean;
};

export const NodeEditButton = ({
  name,
  onClick,
  isCenterAligned,
}: NodeEditButtonProps): ReactElement => {
  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.isCenterAligned]: isCenterAligned,
      })}
      onClick={(event) => handleOnClick(event)}
      aria-label={`Edit ${name} Node`}
      role="button"
    >
      <Icon name="pencil" className={styles.icon} />
    </div>
  );
};
