import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useUpdateNodeMutation } from '../../../../../../redux/diagram/api';
import { iconPickerSelectors } from '../../../../../../redux/iconPicker/slice';
import { RootState } from '../../../../../../redux/store';
import { setCommonIconNodeTypeToEdit } from '../../../../../../redux/diagram/slice';
import { CommonIconEditModalButtons } from '.';

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedCommonIconEditModalButtonsOwnProps,
) => ({
  selectedIcon: iconPickerSelectors.getSelectedIcon(state),
  ...ownProps,
});

const mapDispatchToProps = {
  setCommonIconNodeTypeToEdit,
};

export type ConnectedCommonIconEditModalButtonsOwnProps = {
  display?: DiagramDisplay;
  nodeUid: string;
  existingIcon: string;
};

const CommonIconEditModalButtonsContainer = ({
  display,
  nodeUid,
  existingIcon,
  selectedIcon,
  setCommonIconNodeTypeToEdit,
}: CommonIconEditModalButtonsContainerProps): ReactElement => {
  const [updateNode, { isLoading: isSaving }] = useUpdateNodeMutation();

  return (
    <CommonIconEditModalButtons
      display={display}
      nodeUid={nodeUid}
      existingIcon={existingIcon}
      updateNode={updateNode}
      isSaving={isSaving}
      selectedIcon={selectedIcon}
      setCommonIconNodeTypeToEdit={setCommonIconNodeTypeToEdit}
    />
  );
};

export type CommonIconEditModalButtonsContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

type CommonIconEditModalButtonsRtkProps = {
  isUpdating: boolean;
  updateNode: MutationTrigger<
    MutationDefinition<UpdateDiagramNodeProps, BaseQueryFn, string, DiagramNode>
  >;
};

export type CommonIconEditModalButtonsProps =
  CommonIconEditModalButtonsContainerProps & CommonIconEditModalButtonsRtkProps;

export const ConnectedCommonIconEditModalButtons = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonIconEditModalButtonsContainer);
