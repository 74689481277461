import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';
import { x, y } from '../../../d3';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { updateBoxNode } from '../utils/boxUtil';
import { calculateNewXandY, resetIsDraggingAttribute } from '../../../utils';
import {
  HEADER_OFFSET_VAR,
  NODE_IS_DRAGGING_KEY,
  NODE_IS_DRAGGING_VALUES,
} from '../../../constants';
import { updateBoxSelector } from '../../utils/utils';

interface UseDrag {
  display: DiagramBoxDisplay;
  uid: string;
  isReadOnly: boolean;
  isSelected: boolean;
  nodeRef: RefObject<HTMLDivElement>;
  isSnapToGridEnabled: boolean;
}

export function useDrag({
  display,
  isReadOnly = false,
  nodeRef,
  uid,
  isSelected,
  isSnapToGridEnabled = true,
}: UseDrag): void {
  const [updateNode] = useUpdateNodeMutation();

  const [headerHeightStr] = getComputedStyle(document.body)
    .getPropertyValue(HEADER_OFFSET_VAR)
    .split('px');

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;
    const drag = d3
      .drag<HTMLDivElement, DiagramBoxDisplay>()
      .subject(function (_, d) {
        const transform = d3.zoomTransform(this);
        return {
          x: transform.rescaleX(x)(d.x),
          y: transform.rescaleY(y)(d.y),
        };
      })
      .on('start', function () {
        resetIsDraggingAttribute(d3.select(this));
      })
      .on('drag', function (event) {
        const component = d3.select(this);
        const transform = d3.zoomTransform(this);
        const rect = currentRef!.getBoundingClientRect();
        component.attr(
          'style',
          `transform: translate(${event.x}px, ${event.y}px) scale(${
            transform.k
          }); width: ${rect.width / transform.k}px; height: ${
            rect.height / transform.k
          }px`,
        );
        component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.true);

        updateBoxSelector({
          headerHeight: headerHeightStr,
          newX: event.x,
          newY: event.y,
          scaleFactor: transform.k,
          selection: d3
            .select<SVGRectElement, unknown>(
              `rect[data-box-rect-selector-uid="${uid}"]`,
            )
            .node(),
        });
      })
      .on('end', function (event) {
        const transform = d3.zoomTransform(this);
        const component = d3.select(currentRef!);
        const { x: newX, y: newY } = calculateNewXandY({
          display,
          event,
          isSnapToGridEnabled,
          transform,
          x,
          y,
        });

        updateBoxNode({
          component,
          display: {
            ...display,
            x: newX,
            y: newY,
          },
          nodeUid: uid,
          updateNode,
        });
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .datum(display)
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef).on(
          'mousedown.drag',
          null,
        );
      }
    };
  }, [
    display,
    isReadOnly,
    nodeRef,
    updateNode,
    uid,
    isSelected,
    isSnapToGridEnabled,
    headerHeightStr,
  ]);
}
