import React, { ReactElement, useRef } from 'react';
import classnames from 'classnames';
import { Node } from '../Node';
import { getSelectedIcon } from '../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/GeneralForm/utils';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import {
  handleOnNodeDeleteClick,
  renderNodeActionButtonsHelper,
} from '../utils/utils';
import { DefaultVmNodeDisplay as defaultPosition } from './constants';
import styles from './VmNode.module.css';
import { VMNodeProps } from './container';
import { VmIcons } from './components/VmIcons/VmIcons';
import {
  determineShouldRenderVmNodeActionButtons,
  handleOnEditClick,
  handleOnNodeClick,
} from './utils/utils';

export function VmNode({
  uid,
  asset,
  isReadOnly,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  expired,
  setVmIdToConfigureInDiagram,
  setSelectedNodeToDelete,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
  isDrawingLogicalConnectionFrom,
  setIsDrawingLogicalConnectionFrom,
  isCreatingLogicalConnection,
  iconName,
  ...node
}: VMNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();
  const isValidVmDiagramNode = !!asset?.uid && !isReadOnly;
  const iconClassName = isValidVmDiagramNode ? styles.glow : undefined;
  const hookProps = {
    defaultPosition,
    display: node.display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  const onNodeClick = () => {
    handleOnNodeClick({
      expired,
      setLeftPanelDeviceUid,
      setLeftPanelType,
      uid,
    });
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  const onEditClick = () => {
    handleOnEditClick({
      isValidVmDiagramNode,
      setVmIdToConfigureInDiagram,
      uid: asset?.uid,
    });
  };

  const onDeleteClick = () => {
    handleOnNodeDeleteClick({
      assetType: 'VM',
      name: asset?.name,
      setSelectedNodeToDelete,
      uid: asset?.uid,
    });
  };

  const renderNodeActionButtons = () => {
    return renderNodeActionButtonsHelper({
      name: asset?.name || '',
      onDeleteClick,
      onEditClick,
      shouldRender: determineShouldRenderVmNodeActionButtons({
        isAddingLogicalConnection,
        isValidVmDiagramNode,
      }),
    });
  };

  return (
    <Node
      iconName={iconName}
      isDisabled={hookProps?.display?.hidden}
      className={classnames(styles.root, {
        [styles.vmNode]: isValidVmDiagramNode,
        [styles.addingLogicalConnectionGlow]: isAddingLogicalConnection,
      })}
      ref={nodeRef}
      isReadOnly={isReadOnly}
      onClick={onNodeClick}
      uid={uid}
      isVmNode={true}
      isAddingLogicalConnection={isAddingLogicalConnection}
      isDrawingLogicalConnectionFrom={isDrawingLogicalConnectionFrom}
      setIsDrawingLogicalConnectionFrom={setIsDrawingLogicalConnectionFrom}
      isConnectionAllowed={true}
      isCreatingLogicalConnection={isCreatingLogicalConnection}
    >
      <p className={styles.title}>{asset?.name}</p>
      <div
        className={styles.actionButtonsWrapper}
        id={`actionButtonsWrapper-${uid}`}
      >
        {renderNodeActionButtons()}
      </div>
      <div className={styles.vmIconsWrapper}>
        {getSelectedIcon(hookProps.display?.icon, uid, iconClassName)}
        <VmIcons asset={asset} />
      </div>
    </Node>
  );
}
