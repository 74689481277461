import { connect } from 'react-redux';
import {
  diagramSelectors,
  setCommonIconNodeTypeToEdit,
} from '../../../../redux/diagram/slice';
import { RootState } from '../../../../redux/store';
import { CommonIconEditModal } from '.';

export type CommonIconEditModalContainerOwnProps = {
  display?: DiagramDisplay;
  nodeUid: string;
  existingIcon: string;
  tabOrder: Array<string>;
  availableIcons: IconPickerIcons;
  title?: string;
  nodeType: CommonDiagramIcons;
};

const mapStateToProps = (
  state: RootState,
  ownProps: CommonIconEditModalContainerOwnProps,
) => ({
  commonIconNodeTypeToEdit: diagramSelectors.getCommonIconNodeTypeToEdit(state),
  ...ownProps,
});

const mapDispatchToProps = {
  setCommonIconNodeTypeToEdit,
};

export type CommonIconEditModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const CommonIconEditModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonIconEditModal);
