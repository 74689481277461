export const DOMAIN_DIVISION = 25;
export const GRID_TICKS = 130;
export const HEADER_OFFSET_VAR = '--cui-header-height';
export const SPACE_DIMENSION = 4000;

export enum Zoom {
  Max = 2.75,
  Min = 0.5,
}

export enum DiagramElementIds {
  Board = 'board',
  Grid = 'grid',
}

export const TEMP_LOGICAL_GUIDE_UID = 'TEMP_LOGICAL_GUIDE_UID';

export const TEMP_CONNECTION_GUIDE_LINE: DiagramConnection = {
  _links: {},
  nodes: {
    from: {
      _links: {},
      uid: '',
    },
    to: {
      _links: {},
      uid: '',
    },
  },
  topology: {
    uid: '',
  },
  topologyVersion: {
    uid: '',
  },
  type: 'LOGICAL',
  uid: TEMP_LOGICAL_GUIDE_UID,
};

export const TEMP_CONNECTION_GUIDE_LINE_STROKE_WIDTH = '3';
export const TEMP_CONNECTION_GUIDE_LINE_STROKE_DASH_ARRAY = '7';

export const NODE_CONNECTION_DEFAULT_PADDING = 5;

export const LOGICAL_CONNECTION_MAX_LIMIT = 20;

export const NODE_IS_DRAGGING_KEY = 'data-is-dragging';

export const NODE_IS_DRAGGING_VALUES = { false: null, true: 'true' };

export const COMMON_DIAGRAM_ICONS: Record<string, CommonDiagramIcons> = {
  ANYCONNECT: 'AnyConnect',
  ENDPOINTKIT: 'EndpointKit',
  INTERNET: 'Internet',
  VPODGW: 'vPodGW',
};
