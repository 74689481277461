import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setCommonIconNodeTypeToEdit } from '../../../../redux/diagram/slice';
import { CommonIconEditButton } from '.';

const mapDispatchToProps = {
  setCommonIconNodeTypeToEdit,
};

export type CommonIconEditButtonContainerOwnProps = {
  name: CommonDiagramIcons;
  isAddingLogicalConnection: boolean;
  isReadOnly: boolean;
};

const mapStateToProps = (
  _: RootState,
  ownProps: CommonIconEditButtonContainerOwnProps,
) => ({
  ...ownProps,
});

export type CommonIconEditButtonProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const CommonIconEditButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonIconEditButton);
