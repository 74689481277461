import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type IconPickerState = {
  availableIcons?: IconPickerIcons;
  selectedIcon?: string;
  tempSelectedIcon?: string;
  showButtons?: boolean;
  initialSelectedIcon?: string;
  tabOrder?: Array<string>;
};

export const initialState: IconPickerState = {
  availableIcons: undefined,
  initialSelectedIcon: undefined,
  selectedIcon: undefined,
  showButtons: undefined,
  tempSelectedIcon: undefined,
};

const slice = createSlice({
  initialState,
  name: 'iconPicker',
  reducers: {
    reset: () => ({ ...initialState }),
    setAvailableIcons: (state, action: PayloadAction<IconPickerIcons>) => {
      state.availableIcons = action.payload;
    },
    setInitialSelectedIcon: (state, action: PayloadAction<string>) => {
      state.initialSelectedIcon = action.payload;
    },
    setSelectedIcon: (state, action: PayloadAction<string | undefined>) => {
      state.selectedIcon = action.payload;
    },
    setShowButtons: (state, action: PayloadAction<boolean>) => {
      state.showButtons = action.payload;
    },
    setTabOrder: (state, action: PayloadAction<Array<string>>) => {
      state.tabOrder = action.payload;
    },
  },
});

export const { reducer: iconPickerReducer } = slice;

export const {
  reset,
  setAvailableIcons,
  setSelectedIcon,
  setShowButtons,
  setInitialSelectedIcon,
  setTabOrder,
} = slice.actions;

export const getIconPicker = (state: RootState): IconPickerState =>
  state[slice.name];

export const iconPickerSelectors = {
  getAvailableIcons: createSelector(
    getIconPicker,
    ({ availableIcons }) => availableIcons,
  ),
  getIconsForTab: createSelector(
    getIconPicker,
    (_: RootState, tabTitle: string) => tabTitle,
    ({ availableIcons }, tabTitle) =>
      availableIcons && availableIcons[tabTitle],
  ),
  getInititalSelectedIcon: createSelector(
    getIconPicker,
    ({ initialSelectedIcon }) => initialSelectedIcon,
  ),
  getSelectedIcon: createSelector(
    getIconPicker,
    ({ selectedIcon }) => selectedIcon,
  ),
  getShowButtons: createSelector(
    getIconPicker,
    ({ showButtons }) => showButtons,
  ),
  getTabOrder: createSelector(getIconPicker, ({ tabOrder }) => tabOrder),
};
