import { useEffect } from 'react';
import * as d3 from 'd3';
import { UseConnection } from '../../Connections/hooks/use-connection';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { x, y } from '../../../d3';
import { updateBoxNode } from '../utils/boxUtil';
import {
  HEADER_OFFSET_VAR,
  NODE_IS_DRAGGING_KEY,
  NODE_IS_DRAGGING_VALUES,
} from '../../../constants';
import { updateBoxSelector } from '../../utils/utils';

interface UseResize {
  isReadOnly: boolean;
  display: DiagramBoxDisplay;
  uid: string;
  nodeRef: React.RefObject<HTMLDivElement>;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
}

const ELEMENT_MIN_SIZE = 30;
const ELEMENT_MAX_SIZE = 2000;

export function useResize({
  display,
  isReadOnly,
  nodeRef,
  redrawNodeConnections,
  uid,
}: UseResize): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLSpanElement, DiagramDisplay>()
      .on('drag', function (event) {
        if (!currentRef) return;
        const rect = currentRef.getBoundingClientRect();
        const transform = d3.zoomTransform(this);
        const [pointerX, pointerY] = d3.pointer(event, currentRef);
        const [headerHeightStr] = getComputedStyle(document.body)
          .getPropertyValue(HEADER_OFFSET_VAR)
          .split('px');

        switch (this.dataset.handle) {
          case 'bottomRight': {
            const width = Math.min(
              Math.max(pointerX / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            const height = Math.min(
              Math.max(pointerY / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );

            if (width === ELEMENT_MIN_SIZE || height === ELEMENT_MIN_SIZE)
              return;
            currentRef.style.width = `${width}px`;
            currentRef.style.height = `${height}px`;

            updateBoxSelector({
              headerHeight: headerHeightStr,
              newHeight: height,
              newWidth: width,
              scaleFactor: transform.k,
              selection: d3
                .select<SVGRectElement, unknown>(
                  `rect[data-box-rect-selector-uid="${uid}"]`,
                )
                .node(),
            });

            break;
          }
          case 'bottomLeft': {
            const component = d3.select(currentRef);
            const left = rect.left + pointerX;
            const top = rect.top;

            const width = Math.min(
              Math.max((rect.right - left) / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            const height = Math.min(
              Math.max(pointerY / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            if (width === ELEMENT_MIN_SIZE || height === ELEMENT_MIN_SIZE)
              return;
            component.attr(
              'style',
              `transform: translate(${left}px, ${top}px) scale(${transform.k}); width: ${width}px; height: ${height}px;`,
            );

            updateBoxSelector({
              headerHeight: headerHeightStr,
              newHeight: height,
              newWidth: width,
              newX: left,
              newY: top,
              scaleFactor: transform.k,
              selection: d3
                .select<SVGRectElement, unknown>(
                  `rect[data-box-rect-selector-uid="${uid}"]`,
                )
                .node(),
            });

            break;
          }
          case 'topLeft': {
            const component = d3.select(currentRef);
            const left = rect.left + pointerX;
            const top = rect.top + pointerY;
            const width = Math.min(
              Math.max((rect.right - left) / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            const height = Math.min(
              Math.max((rect.bottom - top) / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            if (width === ELEMENT_MIN_SIZE || height === ELEMENT_MIN_SIZE)
              return;
            component.attr(
              'style',
              `transform: translate(${left}px, ${top}px) scale(${transform.k}); width: ${width}px; height: ${height}px;`,
            );

            updateBoxSelector({
              headerHeight: headerHeightStr,
              newHeight: height,
              newWidth: width,
              newX: left,
              newY: top,
              scaleFactor: transform.k,
              selection: d3
                .select<SVGRectElement, unknown>(
                  `rect[data-box-rect-selector-uid="${uid}"]`,
                )
                .node(),
            });

            break;
          }
          case 'topRight': {
            const component = d3.select(currentRef);
            const left = rect.left;
            const top = rect.top + pointerY;

            const width = Math.min(
              Math.max(pointerX / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            const height = Math.min(
              Math.max((rect.bottom - top) / transform.k, ELEMENT_MIN_SIZE),
              ELEMENT_MAX_SIZE,
            );
            if (width === ELEMENT_MIN_SIZE || height === ELEMENT_MIN_SIZE)
              return;
            component.attr(
              'style',
              `transform: translate(${left}px, ${top}px) scale(${transform.k}); width: ${width}px; height: ${height}px;`,
            );

            updateBoxSelector({
              headerHeight: headerHeightStr,
              newHeight: height,
              newWidth: width,
              newX: left,
              newY: top,
              scaleFactor: transform.k,
              selection: d3
                .select<SVGRectElement, unknown>(
                  `rect[data-box-rect-selector-uid="${uid}"]`,
                )
                .node(),
            });

            break;
          }
        }
      })
      .on('end', function () {
        if (!currentRef) return;

        const transform = d3.zoomTransform(this);
        const rect = currentRef.getBoundingClientRect();
        const invertedX = x.invert(transform.invertX(rect.left));
        const invertedY = y.invert(transform.invertY(rect.top));
        const height = rect.height / transform.k;
        const width = rect.width / transform.k;
        const component = d3.select(currentRef);

        component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.true);
        updateBoxNode({
          component,
          display: {
            ...display,
            height,
            width,
            x: invertedX,
            y: invertedY,
          },
          nodeUid: uid,
          updateNode,
        });
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .selectAll<HTMLSpanElement, DiagramDisplay>(`[data-handle]`)
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
          .selectAll<HTMLSpanElement, DiagramDisplay>(`[data-handle]`)
          .on('mousedown.drag', null);
      }
    };
  }, [display, isReadOnly, nodeRef, redrawNodeConnections, updateNode, uid]);
}
