export const formatTabTitle = (title: string): string => {
  if (title === 'vPodGW') return title;

  const titleWithSpacesInsertedBeforeUppercaseChars = title.replace(
    /([A-Z])/g,
    ' $1',
  );

  return `${titleWithSpacesInsertedBeforeUppercaseChars
    .charAt(0)
    .toUpperCase()}${titleWithSpacesInsertedBeforeUppercaseChars.slice(1)}`;
};
