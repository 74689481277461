import { connect } from 'react-redux';
import { iconPickerSelectors } from '../../../../../../redux/iconPicker/slice';
import { RootState } from '../../../../../../redux/store';
import { FormGeneral } from './FormGeneral';

type FormGeneralContainerOwnProps = {
  hardware: Hardware;
  nodeData?: DiagramNode;
  isModal?: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: FormGeneralContainerOwnProps,
) => ({
  selectedIcon: iconPickerSelectors.getSelectedIcon(state),
  ...ownProps,
});

export type FormGeneralProps = ReturnType<typeof mapStateToProps>;

export const FormGeneralContainer = connect(mapStateToProps)(FormGeneral);
