import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit/dist/createAction';
import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export type HandleOnSave = {
  display?: DiagramDisplay;
  selectedIcon?: string;
  nodeUid: string;
  updateNode: MutationTrigger<
    MutationDefinition<UpdateDiagramNodeProps, BaseQueryFn, string, DiagramNode>
  >;
  setCommonIconNodeTypeToEdit: ActionCreatorWithOptionalPayload<
    CommonDiagramIcons | undefined,
    string
  >;
};

export const handleOnSave = async ({
  display,
  selectedIcon,
  nodeUid,
  updateNode,
  setCommonIconNodeTypeToEdit,
}: HandleOnSave): Promise<void> => {
  const payload = {
    display: {
      ...display,
      icon: selectedIcon,
    },
    isInvalidateDiagramTagNeeded: true,
    nodeUid,
  };

  await updateNode(payload);
  setCommonIconNodeTypeToEdit(undefined);
};
