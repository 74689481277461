import React, { ReactElement } from 'react';
import { IconPickerIconsContainer } from '../IconPickerIcons/container';
import styles from './index.module.css';

export type IconPickerTabContentProps = {
  tabTitle: string;
};

export const IconPickerTabContent = ({
  tabTitle,
}: IconPickerTabContentProps): ReactElement => {
  return (
    <div className={styles.root}>
      <IconPickerIconsContainer tabTitle={tabTitle} />
    </div>
  );
};
