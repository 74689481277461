import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import { CommonIconEditModalContainer } from '../CommonIconEditModal/container';
import { COMMON_DIAGRAM_ICONS } from '../../constants';
import {
  ANYCONNECT_ICON_PICKER_ICONS,
  ANYCONNECT_TAB_ORDER,
} from '../../../../constants/iconPicker';
import { CommonIconEditButtonContainer } from '../CommonIconEditButton/container';
import styles from './AnyconnectNode.module.css';
import { ReactComponent as AnyconnectIcon } from './images/Anyconnect.svg';
import { DefaultAnyconnectNodeDisplay as defaultPosition } from './constants';
import { determineIconToRender } from './utils/utils';

export function AnyconnectNode({
  uid,
  display,
  isReadOnly,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
}: BaseNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();
  const { t } = useTranslation();

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  const DEFAULT_EXISTING_ICON = 'Laptop';

  return (
    <>
      <Node
        isDisabled={display?.hidden}
        className={styles.root}
        ref={nodeRef}
        isReadOnly={isReadOnly}
        uid={uid}
        isAddingLogicalConnection={isAddingLogicalConnection}
        isConnectionAllowed={false}
        iconName={display?.icon}
      >
        <p className={styles.title}>{t('diagram.anyconnect')}</p>
        <div className={styles.actionButtonsWrapper}>
          <CommonIconEditButtonContainer
            isAddingLogicalConnection={isAddingLogicalConnection || false}
            isReadOnly={isReadOnly}
            name={COMMON_DIAGRAM_ICONS.ANYCONNECT}
          />
        </div>
        {determineIconToRender({ display, uid })}
        <AnyconnectIcon className={styles.anyconnectIcon} />
      </Node>
      <CommonIconEditModalContainer
        nodeUid={uid}
        display={display}
        availableIcons={ANYCONNECT_ICON_PICKER_ICONS}
        tabOrder={ANYCONNECT_TAB_ORDER}
        existingIcon={display?.icon || DEFAULT_EXISTING_ICON}
        nodeType={COMMON_DIAGRAM_ICONS.ANYCONNECT}
      />
    </>
  );
}
