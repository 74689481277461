import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { TwoHandleSideNode } from '../TwoHandleSideNode';
import {
  handleOnNodeDeleteClick,
  renderNodeActionButtonsHelper,
} from '../utils/utils';
import styles from './NetworkNode.module.css';
import { NetworkNodeContainerRTKProps } from './NetworkNodeContainer';
import {
  determineShouldRenderNetworkNodeActionButtons,
  handleOnEditNetworkClick,
} from './utils/utils';

type NetworkNodeProps = BaseNodeProps &
  NetworkNodeContainerRTKProps & {
    asset?: Network;
  };

export function NetworkNode({
  asset,
  isReadOnly,
  setNetworkToConfigureInDiagram,
  display,
  uid,
  setSelectedNodeToDelete,
  isAddingLogicalConnection,
}: NetworkNodeProps): ReactElement {
  const isNetworkNodeInDiagramView = !isReadOnly && asset?.uid;

  const handleOnEditClick = () => {
    handleOnEditNetworkClick({
      asset,
      isNetworkNodeInDiagramView,
      setNetworkToConfigureInDiagram,
    });
  };

  const handleOnDeleteClick = () => {
    handleOnNodeDeleteClick({
      assetType: 'NETWORK',
      name: asset?.name,
      setSelectedNodeToDelete,
      uid: asset?.uid,
    });
  };

  const renderNetworkNodeActionButtons = () => {
    return renderNodeActionButtonsHelper({
      name: asset?.name || '',
      onDeleteClick: handleOnDeleteClick,
      onEditClick: handleOnEditClick,
      shouldRender: determineShouldRenderNetworkNodeActionButtons({
        isAddingLogicalConnection,
        isNetworkNodeInDiagramView,
      }),
    });
  };

  return (
    <TwoHandleSideNode
      display={display}
      uid={uid}
      className={styles.root}
      isReadOnly={isReadOnly}
      isAddingLogicalConnection={isAddingLogicalConnection}
    >
      <span className={styles.line}></span>
      <div className={styles.nameContainer}>
        <p className={styles.name}>{asset?.name}</p>
        <p test-id="subnet" className={classNames(styles.name, styles.subnet)}>
          {asset?.inventoryNetwork.subnet}
        </p>
      </div>
      <div
        className={styles.actionButtonsWrapper}
        id={`actionButtonsWrapper-${uid}`}
      >
        {renderNetworkNodeActionButtons()}
      </div>
    </TwoHandleSideNode>
  );
}
