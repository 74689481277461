/* eslint-disable import/namespace */
import React, { ReactElement } from 'react';
import * as IconPickerIcons from '../../../../../assets/images/icons';
import { ReactComponent as InternetCloudImage } from '../images/InternetCloud.svg';

export type DetermineIconToRender = {
  display?: DiagramDisplay;
  uid: string;
};

export const determineIconToRender = ({
  display,
  uid,
}: DetermineIconToRender): ReactElement => {
  if (display?.icon === undefined || display?.icon === 'Cloud')
    return <InternetCloudImage data-component={uid} />;

  const InternetCloudComponent =
    IconPickerIcons[display?.icon as keyof typeof IconPickerIcons];

  return <InternetCloudComponent data-component={uid} />;
};
