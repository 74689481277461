import React from 'react';
import * as IconPickerIcons from '../../../../../../../../assets/images/icons';
import {
  AccessPoint,
  Cloud,
  Firewall,
  GenericBuilding,
  Laptop,
  RouterIcon,
  Server,
  Storage,
  SwitchIcon,
  Telephone,
  WLC,
} from '../../../../../../../../assets/images/icons';

export const getSelectedIcon = (
  currentIcon?: string,
  uid?: string,
  className?: string,
): JSX.Element => {
  const Component =
    IconPickerIcons[
      // eslint-disable-next-line import/namespace
      currentIcon as keyof typeof IconPickerIcons
    ];

  if (Component)
    return <Component data-component={uid} className={className} />;

  switch (currentIcon) {
    case 'Laptop':
      return <Laptop data-component={uid} className={className} />;
    case 'Access Point':
      return <AccessPoint data-component={uid} className={className} />;
    case 'Cloud':
      return <Cloud data-component={uid} className={className} />;
    case 'Firewall':
      return <Firewall data-component={uid} className={className} />;
    case 'Generic Building':
      return <GenericBuilding data-component={uid} className={className} />;
    case 'Router':
      return <RouterIcon data-component={uid} className={className} />;
    case 'Server':
      return <Server data-component={uid} className={className} />;
    case 'Storage':
      return <Storage data-component={uid} className={className} />;
    case 'Switch':
      return <SwitchIcon data-component={uid} className={className} />;
    case 'Telephone':
      return <Telephone data-component={uid} className={className} />;
    case 'WLC':
      return <WLC data-component={uid} className={className} />;
    default:
      return <Laptop data-component={uid} className={className} />;
  }
};
