import { D3DragEvent } from 'd3';
import {
  isUpdateToNodesCoordsRequired,
  calculateNewCoordinate,
  updateNodeDisplayHelper,
} from '../../utils';

type CalculateNewX1andY1andX2andY2 = {
  isSnapToGridEnabled?: boolean;
  x: d3.ScaleLinear<number, number, never>;
  y: d3.ScaleLinear<number, number, never>;
  display?: DiagramDisplay;
  event: D3DragEvent<HTMLDivElement, DiagramDisplay, unknown>;
  uid: string;
  transform: d3.ZoomTransform;
  leftHandleMatrix: DOMMatrix;
  rightHandleMatrix: DOMMatrix;
};

export const calculateNewX1andY1andX2andY2 = ({
  event,
  isSnapToGridEnabled,
  x,
  y,
  display,
  transform,
  leftHandleMatrix,
  rightHandleMatrix,
}: CalculateNewX1andY1andX2andY2): {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
} => {
  const x1 = x.invert(transform.invertX(leftHandleMatrix.m41));
  const y1 = y.invert(transform.invertY(leftHandleMatrix.m42));
  const x2 = x.invert(transform.invertX(rightHandleMatrix.m41));
  const y2 = y.invert(transform.invertY(rightHandleMatrix.m42));
  const isUserOverridingSnapToGrid = event.sourceEvent.altKey;

  let newX1 = display?.x1;
  let newY1 = display?.y1;
  let newX2 = display?.x2;
  let newY2 = display?.y2;

  const isUpdateToNodesCoordsRequiredResult =
    isUpdateToNodesCoordsRequired({
      invertedX: x1,
      invertedY: y1,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
      x: display?.x1,
      y: display?.y1,
    }) ||
    isUpdateToNodesCoordsRequired({
      invertedX: x2,
      invertedY: y2,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
      x: display?.x2,
      y: display?.y2,
    });

  if (isUpdateToNodesCoordsRequiredResult) {
    newX1 = calculateNewCoordinate({
      coordinate: x1,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });

    newY1 = calculateNewCoordinate({
      coordinate: y1,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });

    newX2 = calculateNewCoordinate({
      coordinate: x2,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });

    newY2 = calculateNewCoordinate({
      coordinate: y2,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });
  }

  return {
    x1: newX1!,
    x2: newX2!,
    y1: newY1!,
    y2: newY2!,
  };
};

type UpdateTwoHandleLineNode = {
  component: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  updateNode: RTKMutation<UpdateDiagramNodeProps, UpdateDiagramNodeProps>;
  display?: DiagramDisplay;
  uid: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const updateTwoHandleLineNode = ({
  component,
  display,
  uid,
  updateNode,
  x1,
  y1,
  x2,
  y2,
}: UpdateTwoHandleLineNode): void =>
  updateNodeDisplayHelper({
    component,
    display: {
      ...(display || {}),
      x1,
      x2,
      y1,
      y2,
    },
    nodeUid: uid,
    updateNode,
  });
