import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';
import { calculateDefaultPositionOnGraphBasedOnType, x, y } from '../../../d3';
import { UseConnection } from '../../Connections/hooks/use-connection';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import {
  resetIsDraggingAttribute,
  updateNodeDisplayHelper,
} from '../../../utils';
import {
  NODE_IS_DRAGGING_KEY,
  NODE_IS_DRAGGING_VALUES,
} from '../../../constants';
import { determineIfNodeDragIsAllowed } from '../../utils/utils';

interface UseDrag extends BaseNodeProps {
  nodeRef: RefObject<HTMLDivElement>;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
}

export function useDrag({
  display,
  isReadOnly = false,
  nodeRef,
  redrawNodeConnections,
  uid,
}: UseDrag): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLDivElement, DiagramDisplay>()
      .subject(function (_, d) {
        const transform = d3.zoomTransform(this);
        const defaultPositions =
          calculateDefaultPositionOnGraphBasedOnType(this);

        return {
          x: transform.rescaleX(x)(d?.x1 || defaultPositions.x1),
          y: transform.rescaleY(y)(d?.y1 || defaultPositions.y),
        };
      })
      .on('start', function () {
        resetIsDraggingAttribute(d3.select(this));
      })
      .on('drag', function (event) {
        if (
          determineIfNodeDragIsAllowed({
            actionButtonsElement: d3
              .select<HTMLDivElement, unknown>(`#actionButtonsWrapper-${uid}`)
              .node(),
            clickedElement: event.sourceEvent.target,
          })
        ) {
          const component = d3.select(this);
          const transform = d3.zoomTransform(this);
          const defaultXY = calculateDefaultPositionOnGraphBasedOnType(this);
          const x1 = display?.x1 || defaultXY.x1;
          const x2 = display?.x2 || defaultXY.x2;

          component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.true);
          component.attr(
            'style',
            `transform: translate(${event.x}px, ${event.y}px) scale(${
              transform.k
            }); width: ${Math.abs(x(x1) - x(x2))}px`,
          );

          redrawNodeConnections && redrawNodeConnections(uid);
        }
      })
      .on('end', function (event) {
        const transform = d3.zoomTransform(this);
        const invertedX = x.invert(transform.invertX(event.x));
        const invertedY = y.invert(transform.invertY(event.y));
        const defaultXY = calculateDefaultPositionOnGraphBasedOnType(this);
        const component = d3.select(this);

        const x1 = display?.x1 || defaultXY.x1;
        const x2 = display?.x2 || defaultXY.x2;
        const newDisplay = {
          ...(display || {}),
          x1: invertedX,
          x2: invertedX + x2 - x1,
          y1: invertedY,
        };

        updateNodeDisplayHelper({
          component,
          display: newDisplay,
          nodeUid: uid,
          updateNode,
        });

        if (currentRef) {
          currentRef.style.zIndex = '0';
        }
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .datum(display || ({} as DiagramDisplay))
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef).on(
          'mousedown.drag',
          null,
        );
      }
    };
  }, [display, isReadOnly, nodeRef, redrawNodeConnections, updateNode, uid]);
}
