export const ANYCONNECT_TAB_ORDER = ['anyConnect'];
export const ANYCONNECT_ICON_PICKER_ICONS = {
  anyConnect: [
    { label: 'Classic', name: 'Laptop' },
    { label: 'Modern', name: 'IconMarketingDevLaptopComputer' },
  ],
};

export const INTERNET_TAB_ORDER = ['internet'];
export const INTERNET_ICON_PICKER_ICONS = {
  internet: [
    { label: 'Classic', name: 'Cloud' },
    { label: 'Modern', name: 'IconMarketingNetCloud' },
  ],
};

export const VPODGW_TAB_ORDER = ['vPodGW'];
export const VPODGW_ICON_PICKER_ICONS = {
  vPodGW: [
    { label: 'Classic', name: 'RouterIcon' },
    { label: 'Modern', name: 'IconMarketingNetRouter' },
  ],
};

export const ENDPOINT_KIT_TAB_ORDER = ['endpointKit'];
export const ENDPOINT_ICON_PICKER_ICONS = {
  endpointKit: [
    { label: 'Classic', name: 'EndpointKit' },
    { label: 'Modern', name: 'IconMarketingCustomEndpointKit' },
  ],
};

export const DEFAULT_TAB_ORDER = [
  'common',
  'businessIndustry',
  'devices',
  'healthcare',
  'networking',
  'symbols',
  'travelAndTransportation',
  'original',
];

export const DEFAULT_ICON_PICKER_ICONS = {
  businessIndustry: [
    { label: '8x5', name: 'IconMarketingBus8x5' },
    { label: '24x7x4', name: 'IconMarketingBus24x7x4' },
    { label: 'Agriculture', name: 'IconMarketingBusAgriculture' },
    { label: 'Business', name: 'IconMarketingBusBusiness' },
    { label: 'Cart', name: 'IconMarketingBusCart' },
    { label: 'Cashless', name: 'IconMarketingBusCashless' },
    {
      label: 'Credit Card / Payment',
      name: 'IconMarketingBusCreditCardPayment',
    },
    {
      label: 'Cstore - Women-owned Business',
      name: 'IconMarketingBusCstoreWomenOwnedBusiness',
    },
    {
      label: 'Customer Journey Platform',
      name: 'IconMarketingBusCustomerJourneyPlatform',
    },
    {
      label: 'Customer Journey Solutions',
      name: 'IconMarketingBusCustomerJourneySolutions',
    },
    { label: 'Employment', name: 'IconMarketingBusEmployment' },
    {
      label: 'Enterprise Agreement',
      name: 'IconMarketingBusEnterpriseAgreement',
    },
    { label: 'Global Currency', name: 'IconMarketingBusGlobalCurrency' },
    { label: 'Global Procurement', name: 'IconMarketingBusGlobalProcurement' },
    { label: 'Manufacturing', name: 'IconMarketingBusManufacturing' },
    {
      label: 'Marketing Ad Operations',
      name: 'IconMarketingBusMarketingAdOperations',
    },
    { label: 'Paid Search', name: 'IconMarketingBusPaidSearch' },
    { label: 'Paid Social', name: 'IconMarketingBusPaidSocial' },
    {
      label: 'Planting / Harvesting',
      name: 'IconMarketingBusPlantingHarvesting',
    },
    { label: 'Retail', name: 'IconMarketingBusRetail' },
    { label: 'Satellite', name: 'IconMarketingBusSatellite' },
    { label: 'Segmentation', name: 'IconMarketingBusSegmentation' },
    { label: 'Services', name: 'IconMarketingBusServices' },
    { label: 'Services Alt', name: 'IconMarketingBusServicesAlt' },
    { label: 'Small Business', name: 'IconMarketingBusSmallBusiness' },
    { label: 'Smart Cities', name: 'IconMarketingBusSmartCities' },
    { label: 'Solution', name: 'IconMarketingBusSolution' },
    { label: 'Suppliers', name: 'IconMarketingBusSuppliers' },
    { label: 'Supply Chain', name: 'IconMarketingBusSupplyChain' },
    {
      label: 'Technology Financing',
      name: 'IconMarketingBusTechnologyFinancing',
    },
  ],
  common: [
    { label: 'Laptop', name: 'IconMarketingDevLaptopComputer' },
    { label: 'Server', name: 'IconMarketingNetServer' },
    { label: 'Cloud', name: 'IconMarketingNetCloud' },
    { label: 'Firewall', name: 'IconMarketingNetFirewall' },
    { label: 'SD WAN Router', name: 'IconMarketingNetSdWanRouter' },
    { label: 'Router', name: 'IconMarketingNetRouter' },
    { label: 'Storage', name: 'IconMarketingNetStorage' },
    { label: 'Switch', name: 'IconMarketingNetSwitch' },
    { label: 'Desk Phone', name: 'IconMarketingDevDeskPhone' },
    { label: 'Office', name: 'IconMarketingTraHotel' },
    {
      label: 'MDS Multilayer Switches',
      name: 'IconMarketingNetMdsMultilayerSwitches',
    },
  ],
  devices: [
    { label: 'Camera', name: 'IconMarketingDevCamera' },
    { label: 'Cisco Refresh', name: 'IconMarketingDevCiscoRefresh' },
    { label: 'Desk Phone', name: 'IconMarketingDevDeskPhone' },
    { label: 'Desktop Computer', name: 'IconMarketingDevDesktopComputer' },
    { label: 'Devices', name: 'IconMarketingDevDevices' },
    { label: 'Headset', name: 'IconMarketingDevHeadset' },
    { label: 'Laptop Computer', name: 'IconMarketingDevLaptopComputer' },
    { label: 'Machine Learning', name: 'IconMarketingDevMachineLearning' },
    { label: 'My Workplace', name: 'IconMarketingDevMyWorkplace' },
    { label: 'Phone', name: 'IconMarketingDevPhone' },
    { label: 'Shared Content', name: 'IconMarketingDevSharedContent' },
    { label: 'Smartwatch', name: 'IconMarketingDevSmartwatch' },
    { label: 'Tablet', name: 'IconMarketingDevTablet' },
    { label: 'Tablet', name: 'IconMarketingDevTablet1' },
    { label: 'Telepresence', name: 'IconMarketingDevTelepresence' },
    { label: 'Video Player', name: 'IconMarketingDevVideoPlayer' },
    { label: 'Webcam', name: 'IconMarketingDevWebcam' },
  ],
  healthcare: [
    { label: 'Health Insights', name: 'IconMarketingHeaHealthInsights' },
    { label: 'Healthcare', name: 'IconMarketingHeaHealthcare' },
    {
      label: 'Healthcare Facility',
      name: 'IconMarketingHeaHealthcareFacility',
    },
    { label: 'Healthcare Worker', name: 'IconMarketingHeaHealthcareWorker' },
    { label: 'Mask', name: 'IconMarketingHeaMask' },
    { label: 'Patient', name: 'IconMarketingHeaPatient' },
    { label: 'Social distanced', name: 'IconMarketingHeaSocialDistance' },
  ],
  networking: [
    { label: '3rd Party Licenses', name: 'IconMarketingNet3rdPartLicenses' },
    { label: '5G SD-WAN', name: 'IconMarketingNet5gSdWan' },
    { label: 'APIC', name: 'IconMarketingNetApic' },
    { label: 'APIC Cloud', name: 'IconMarketingNetApicCloud' },
    { label: 'As a Service', name: 'IconMarketingNetAsAService' },
    {
      label: 'Automated Subsea Tuning',
      name: 'IconMarketingNetAutomatedSubseaTuning',
    },
    { label: 'Bare Metal', name: 'IconMarketingNetBareMetal' },
    {
      label: 'Bare Metal Performance',
      name: 'IconMarketingNetBareMetalPerformance',
    },
    { label: 'Blade Servers', name: 'IconMarketingNetBladeServers' },
    { label: 'Catalyst RAN', name: 'IconMarketingNetCatalystRan' },
    { label: 'Cellular', name: 'IconMarketingNetCellular' },
    { label: 'Chassis', name: 'IconMarketingNetChassis' },
    {
      label: 'Cisco + Secure Connect',
      name: 'IconMarketingNetCiscoAndSecureConnect',
    },
    { label: 'Cisco DNA Center', name: 'IconMarketingNetCiscoDnaCenter' },
    { label: 'Cisco Plus', name: 'IconMarketingNetCiscoPlus' },
    {
      label: 'Cisco Plus - Bare Metal',
      name: 'IconMarketingNetCiscoPlusBareMetal',
    },
    { label: 'Cisco Plus Cloud', name: 'IconMarketingNetCiscoPlusCloud' },
    {
      label: 'Cisco Plus Create Your Own',
      name: 'IconMarketingNetCiscoPlusCreateYourOwn',
    },
    { label: 'Cisco Plus - DCNaaS', name: 'IconMarketingNetCiscoPlusDcnaas' },
    {
      label: 'Cisco Plus - Edge Computing',
      name: 'IconMarketingNetCiscoPlusEdgeComputing',
    },
    {
      label: 'Cisco Plus - Flexible Consumption',
      name: 'IconMarketingNetCiscoPlusFlexibleConsumption',
    },
    {
      label: 'Cisco Plus for SASE \n (Secure Access Server Edge)',
      name: 'IconMarketingNetCiscoPlusForSase',
    },
    {
      label: 'Cisco Plus for Storage',
      name: 'IconMarketingNetCiscoPlusForStorage',
    },
    { label: 'Cisco Plus - NaaS', name: 'IconMarketingNetCiscoPlusPlusNaas' },
    {
      label: 'Cisco Plus Pre Defined Use Cases',
      name: 'IconMarketingNetCiscoPlusPredefinedUseCases',
    },
    {
      label: 'Cisco Plus - Virtualization',
      name: 'IconMarketingNetCiscoPlusVirtualization',
    },
    { label: 'Cloud', name: 'IconMarketingNetCloud' },
    { label: 'Cloud Analytics', name: 'IconMarketingNetCloudAnalytics' },
    { label: 'Cloud Applications', name: 'IconMarketingNetCloudApplications' },
    { label: 'Cloud Calling', name: 'IconMarketingNetCloudCalling' },
    { label: 'Cloud Center', name: 'IconMarketingNetCloudCenter' },
    { label: 'Cloud Delivered', name: 'IconMarketingNetCloudDelivered' },
    {
      label: 'Cloud Infrastructure',
      name: 'IconMarketingNetCloudInfrastructure',
    },
    {
      label: 'Cloud Professional Services',
      name: 'IconMarketingNetCloudProfessionalServices',
    },
    {
      label: 'Cloud Services Stack',
      name: 'IconMarketingNetCloudServicesStack',
    },
    { label: 'Computer storage', name: 'IconMarketingNetComputerStorage' },
    {
      label: 'Converged SDN Transport',
      name: 'IconMarketingNetConvergedSdnTransport',
    },
    { label: 'Crosswork Cloud', name: 'IconMarketingNetCrossworkCloud' },
    { label: 'Data Center', name: 'IconMarketingNetDataCenter' },
    { label: 'Data Platform', name: 'IconMarketingNetDataPlatform' },
    { label: 'DCNaaS', name: 'IconMarketingNetDcnaas' },
    { label: 'Demand Gen', name: 'IconMarketingNetDemandGen' },
    { label: 'DevNet', name: 'IconMarketingNetDevNet' },
    { label: 'Edge Computing', name: 'IconMarketingNetEdgeComputing' },
    { label: 'Endpoints', name: 'IconMarketingNetEndpoints' },
    {
      label: 'Enterprise Data Management',
      name: 'IconMarketingNetEnterpriseDataManagement',
    },
    { label: 'Ethernet VPN (EVPN)', name: 'IconMarketingNetEthernetVpnEvpn' },
    { label: 'Fastlane', name: 'IconMarketingNetFastlane' },
    { label: 'Firewall', name: 'IconMarketingNetFirewall' },
    {
      label: 'Flexible consumption',
      name: 'IconMarketingNetFlexibleConsumption',
    },
    { label: 'HX VDI', name: 'IconMarketingNetHxVdi' },
    { label: 'HX VSI', name: 'IconMarketingNetHxVsi' },
    {
      label: 'HXDP Software Premier',
      name: 'IconMarketingNetHxdpSoftwarePremier',
    },
    {
      label: 'HXDP Software Standard',
      name: 'IconMarketingNetHxdpSoftwareStandard',
    },
    { label: 'Hybrid Cloud', name: 'IconMarketingNetHybridCloud' },
    { label: 'Hybrid IT', name: 'IconMarketingNetHybridIt' },
    {
      label: 'Hyperflex-AllFlash',
      name: 'IconMarketingNetHyperflexAllflash',
    },
    {
      label: 'Hyperflex-Data-Center',
      name: 'IconMarketingNetHyperflexDataCenter',
    },
    {
      label: 'Hyperflex-Edge Computing',
      name: 'IconMarketingNetHyperflexEdgeComputing',
    },
    {
      label: 'Hyperflex-Hybrid-Nodes',
      name: 'IconMarketingNetHyperflexHybridNodes',
    },
    {
      label: 'Hyperflex-SW-Data-Center',
      name: 'IconMarketingNetHyperflexSwDataCenter',
    },
    { label: 'IaaS', name: 'IconMarketingNetIaas' },
    {
      label: 'ISE - Identity Services Engineer',
      name: 'IconMarketingNetIdentifyServicesEngineer',
    },
    { label: 'Infrastructure', name: 'IconMarketingNetInfrastructure' },
    {
      label: 'Integration and Scaling',
      name: 'IconMarketingNetIntegrationAndScaling',
    },
    {
      label: 'Internet Services Engine',
      name: 'IconMarketingNetInternetServicesEngine',
    },
    {
      label: 'Intersight Workload Optimizer',
      name: 'IconMarketingNetIntersightWorkloadOptimizer',
    },
    {
      label: 'MDS Multilayer Switches',
      name: 'IconMarketingNetMdsMultilayerSwitches',
    },
    { label: 'MDS Premier', name: 'IconMarketingNetMdsPremier' },
    { label: 'Mesh', name: 'IconMarketingNetMesh' },
    { label: 'Microsoft', name: 'IconMarketingNetMicrosoft' },
    {
      label: 'Multi-cloud Infrastructure',
      name: 'IconMarketingNetMultiCloudInfrastructure',
    },
    {
      label: 'Multicloud Management',
      name: 'IconMarketingNetMulticloudManagement',
    },
    {
      label: 'Multicloud Security',
      name: 'IconMarketingNetMulticloudSecurity',
    },
    { label: 'NetApp', name: 'IconMarketingNetNetapp' },
    { label: 'Network Insights', name: 'IconMarketingNetNetworkInsights' },
    {
      label: 'Network visibility - Security for ISE-SW',
      name: 'IconMarketingNetNetworkVisibilitySecurityForIseSw',
    },
    { label: 'Networking', name: 'IconMarketingNetNetworking' },
    { label: 'Nexus 3', name: 'IconMarketingNetNexus3' },
    { label: 'Nexus ACI', name: 'IconMarketingNetNexusAci' },
    {
      label: 'Nexus Dashboard Databroker',
      name: 'IconMarketingNetNexusDashboardDatabroker',
    },
    {
      label: 'Nexus Dashboard Fabric Controller',
      name: 'IconMarketingNetNexusDashboardFabricController',
    },
    {
      label: 'Nexus Dashboard ND Fabric Discovery',
      name: 'IconMarketingNetNexusDashboardNdFabricDiscovery',
    },
    {
      label: 'Nexus Dashboard ND Insights',
      name: 'IconMarketingNetNexusDashboardNdInsights',
    },
    {
      label: 'Nexus Dashboard Orchestrator',
      name: 'IconMarketingNetNexusDashboardOrchestrator',
    },
    {
      label: 'Nexus Dashboard SAN Controller',
      name: 'IconMarketingNetNexusDashboardSanController',
    },
    { label: 'Nexus Essentials', name: 'IconMarketingNetNexusEssentials' },
    { label: 'Nexus Premier', name: 'IconMarketingNetNexusPremier' },
    { label: 'Nexus Switches', name: 'IconMarketingNetNexusSwitches' },
    {
      label: 'Optimization Engine',
      name: 'IconMarketingNetOptimizationEngine',
    },
    { label: 'PayG 1', name: 'IconMarketingNetPayg1' },
    { label: 'PayU 1', name: 'IconMarketingNetPayu1' },
    { label: 'Personal Room', name: 'IconMarketingNetPersonalRoom' },
    { label: 'Policy', name: 'IconMarketingNetPolicy' },
    { label: 'Private 5G', name: 'IconMarketingNetPrivate5g' },
    { label: 'Private Cloud', name: 'IconMarketingNetPrivateCloud' },
    {
      label: 'Programability and Orchestration',
      name: 'IconMarketingNetProgramabilityAndOrchestration',
    },
    { label: 'Public 5G', name: 'IconMarketingNetPublic5g' },
    { label: 'Public Cloud', name: 'IconMarketingNetPublicCloud' },
    { label: 'Pure Storage', name: 'IconMarketingNetPureStorage' },
    { label: 'Rack Servers', name: 'IconMarketingNetRackServers' },
    { label: 'Red Hat', name: 'IconMarketingNetRedHat' },
    { label: 'Router', name: 'IconMarketingNetRouter' },
    { label: 'SaaS', name: 'IconMarketingNetSaas' },
    { label: 'SASE (Secure Access Server Edge)', name: 'IconMarketingNetSase' },
    { label: 'SD Access', name: 'IconMarketingNetSdAccess' },
    { label: 'SD WAN Router', name: 'IconMarketingNetSdWanRouter' },
    { label: 'Secure Search', name: 'IconMarketingNetSecureSearch' },
    { label: 'Security Threat', name: 'IconMarketingNetSecurityThreat' },
    { label: 'Segment Routing', name: 'IconMarketingNetSegmentRouting' },
    { label: 'Server Storage', name: 'IconMarketingNetServerStorage' },
    { label: 'Server', name: 'IconMarketingNetServer' },
    { label: 'SFP Cable', name: 'IconMarketingNetSfpCable' },
    { label: 'Silicone 1', name: 'IconMarketingNetSilicone1' },
    { label: 'Software Developer', name: 'IconMarketingNetSoftwareDeveloper' },
    {
      label: 'Software Development',
      name: 'IconMarketingNetSoftwareDevelopment',
    },
    { label: 'Software', name: 'IconMarketingNetSoftware' },
    {
      label: 'Softwre-defined WAN (SD-WAN)',
      name: 'IconMarketingNetSoftwreDefinedWan',
    },
    { label: 'Storage', name: 'IconMarketingNetStorage1' },
    { label: 'Storage', name: 'IconMarketingNetStorage' },
    { label: 'Switch Spine', name: 'IconMarketingNetSwitchSpine' },
    { label: 'Switch', name: 'IconMarketingNetSwitch' },
    { label: 'Update Firmware', name: 'IconMarketingNetUpdateFirmware' },
    {
      label: 'User Identity Access',
      name: 'IconMarketingNetUserIdentifyAccess',
    },
    { label: 'v storage', name: 'IconMarketingNetVStorage' },
    { label: 'VDI Balance', name: 'IconMarketingNetVdiBalance' },
    { label: 'VDI Plus', name: 'IconMarketingNetVdiPlus' },
    { label: 'VDI', name: 'IconMarketingNetVdi' },
    {
      label: 'Virtual Infrastructure Management',
      name: 'IconMarketingNetVirtualInfrastructureManagement',
    },
    { label: 'Virtualization', name: 'IconMarketingNetVirtualization' },
    { label: 'VMWare', name: 'IconMarketingNetVmware' },
    { label: 'VSI Performance', name: 'IconMarketingNetVsiPerformance' },
    { label: 'VSI', name: 'IconMarketingNetVsi' },
    { label: 'Wi-Fi 6', name: 'IconMarketingNetWifi6' },
    { label: 'Wi-Fi 6E', name: 'IconMarketingNetWifi6e' },
    { label: 'Zero Trust', name: 'IconMarketingNetZeroTrust' },
  ],
  original: [
    { label: 'Laptop', name: 'Laptop' },
    { label: 'Server', name: 'Server' },
    { label: 'Cloud', name: 'Cloud' },
    { label: 'Firewall', name: 'Firewall' },
    { label: 'Storage', name: 'Storage' },
    { label: 'Router', name: 'RouterIcon' },
    { label: 'Switch', name: 'SwitchIcon' },
    { label: 'Access Point', name: 'AccessPoint' },
    { label: 'Generic Building', name: 'GenericBuilding' },
    { label: 'Telephone', name: 'Telephone' },
    { label: 'WLC', name: 'WLC' },
    { label: 'Public Cloud', name: 'PublicCloudDefault' },
    { label: 'SaaS Connect', name: 'SaaSConnect' },
  ],
  symbols: [
    { label: 'Acknowledge', name: 'IconMarketingSymAcknowledge' },
    { label: 'Administrator', name: 'IconMarketingSymAdministrator' },
    { label: 'Advisory', name: 'IconMarketingSymAdvisory' },
    { label: 'Agility', name: 'IconMarketingSymAgility' },
    { label: 'Alert', name: 'IconMarketingSymAlert' },
    { label: 'Applications', name: 'IconMarketingSymApplications' },
    { label: 'Ask Brand', name: 'IconMarketingSymAskBrand' },
    { label: 'Audio Recording', name: 'IconMarketingSymAudioRecording' },
    { label: 'Budget Calendar', name: 'IconMarketingSymBudgetCalendar' },
    { label: 'Calendar', name: 'IconMarketingSymCalendar' },
    { label: 'Cart Download', name: 'IconMarketingSymCartDownload' },
    { label: 'Certifications', name: 'IconMarketingSymCertifications' },
    {
      label: 'Cisco Digital Platforms and Tools',
      name: 'IconMarketingSymCiscoDigitalPlatformsAndTools',
    },
    {
      label: 'Cognitive Intelligence',
      name: 'IconMarketingSymCognitiveIntelligence',
    },
    { label: 'Collaboration', name: 'IconMarketingSymCollaboration' },
    {
      label: 'Connect with experts',
      name: 'IconMarketingSymConnectWithExperts',
    },
    { label: 'Contact Action', name: 'IconMarketingSymContactAction' },
    { label: 'Containers', name: 'IconMarketingSymContainers' },
    {
      label: 'Cstore - Charitable Donation',
      name: 'IconMarketingSymCstoreCharitableDonation',
    },
    {
      label: 'Cstore - Inclusive Community Collaboration',
      name: 'IconMarketingSymCstoreInclusiveCommunityCollaboration',
    },
    { label: 'Cstore - Recycling', name: 'IconMarketingSymCstoreRecycling' },
    {
      label: 'Cstore - Socially Conscious 2',
      name: 'IconMarketingSymCstoreSociallyConscious2',
    },
    {
      label: 'Cstore - Sustainability',
      name: 'IconMarketingSymCstoreSustainability',
    },
    { label: 'Data', name: 'IconMarketingSymData' },
    {
      label: 'Data Driven Insights',
      name: 'IconMarketingSymDataDrivenInsights',
    },
    { label: 'Digital Web Design', name: 'IconMarketingSymDigitalWebDesign' },
    { label: 'Download', name: 'IconMarketingSymDownload' },
    { label: 'Energy', name: 'IconMarketingSymEnergy' },
    { label: 'Event', name: 'IconMarketingSymEvent' },
    { label: 'File', name: 'IconMarketingSymFile' },
    { label: 'Folder 1', name: 'IconMarketingSymFolder1' },
    { label: 'Folder 2', name: 'IconMarketingSymFolder2' },
    { label: 'Graduate', name: 'IconMarketingSymGraduate' },
    {
      label: 'Graphics and Presentations',
      name: 'IconMarketingSymGraphicsAndPresentations',
    },
    { label: 'Heart/emotion', name: 'IconMarketingSymHeartEmotion' },
    { label: 'Heart/emotion 2', name: 'IconMarketingSymHeartEmotion2' },
    { label: 'Home', name: 'IconMarketingSymHome' },
    { label: 'Implementation', name: 'IconMarketingSymImplementation' },
    { label: 'Information', name: 'IconMarketingSymInformation' },
    { label: 'Insights', name: 'IconMarketingSymInsights' },
    { label: 'Intelligent', name: 'IconMarketingSymIntelligent' },
    {
      label: 'Internal EMail Services',
      name: 'IconMarketingSymInternetMailServices',
    },
    { label: 'IoT', name: 'IconMarketingSymIot' },
    { label: 'Location', name: 'IconMarketingSymLocation' },
    { label: 'Maintenance', name: 'IconMarketingSymMaintenance' },
    {
      label: 'Marketing Input Brief',
      name: 'IconMarketingSymMarketingInputBrief',
    },
    { label: 'Motion Graphics', name: 'IconMarketingSymMotionGraphics' },
    { label: 'My Leader', name: 'IconMarketingSymMyLeader' },
    { label: 'Network Security', name: 'IconMarketingSymNetworkSecurity' },
    { label: 'New', name: 'IconMarketingSymNew' },
    { label: 'Operations', name: 'IconMarketingSymOperations' },
    { label: 'Optimization', name: 'IconMarketingSymOptimization' },
    { label: 'Partnerships', name: 'IconMarketingSymPartnerships' },
    { label: 'Predictable', name: 'IconMarketingSymPredictable' },
    { label: 'Print', name: 'IconMarketingSymPrint' },
    { label: 'Process', name: 'IconMarketingSymProcess' },
    { label: 'Protected', name: 'IconMarketingSymProtected' },
    { label: 'Question', name: 'IconMarketingSymQuestion' },
    { label: 'Real Time Data', name: 'IconMarketingSymRealTimeData' },
    {
      label: 'Recently Used Product',
      name: 'IconMarketingSymRecentlyUsedProduct',
    },
    { label: 'Recently Used', name: 'IconMarketingSymRecentlyUsed' },
    { label: 'Recycling', name: 'IconMarketingSymRecycling' },
    { label: 'Secure', name: 'IconMarketingSymSecure1' },
    { label: 'Secure 3', name: 'IconMarketingSymSecure3' },
    { label: 'Secure', name: 'IconMarketingSymSecure' },
    { label: 'Service Provider', name: 'IconMarketingSymServiceProvider' },
    { label: 'Settings', name: 'IconMarketingSymSettings' },
    { label: 'Simple', name: 'IconMarketingSymSimple' },
    { label: 'Simplify', name: 'IconMarketingSymSimplify' },
    { label: 'Social Impact/CSR', name: 'IconMarketingSymSocialImpactCsr' },
    { label: 'Social Media', name: 'IconMarketingSymSocialMedia' },
    {
      label: 'System Marks and Identities',
      name: 'IconMarketingSymSystemMarksAndIdentities',
    },
    {
      label: 'Technical Education',
      name: 'IconMarketingSymTechnicalEducation',
    },
    { label: 'Technical', name: 'IconMarketingSymTechnical' },
    {
      label: 'Telepresence Scheduled',
      name: 'IconMarketingSymTelepresenceScheduled',
    },
    {
      label: 'Thought Leadership 2',
      name: 'IconMarketingSymThoughtLeadership2',
    },
    { label: 'Thought Leadership', name: 'IconMarketingSymThoughtLeadership' },
    { label: 'Tip', name: 'IconMarketingSymTip' },
    { label: 'Tools', name: 'IconMarketingSymTools' },
    { label: 'Training', name: 'IconMarketingSymTraining' },
    { label: 'User', name: 'IconMarketingSymUser' },
    { label: 'Utilities', name: 'IconMarketingSymUtilities' },
    { label: 'Video Recording', name: 'IconMarketingSymVideoRecording' },
    { label: 'Video', name: 'IconMarketingSymVideo' },
    { label: 'Web', name: 'IconMarketingSymWeb' },
    { label: 'Wi-Fi', name: 'IconMarketingSymWifi' },
    { label: 'World alt', name: 'IconMarketingSymWorldAlt' },
    { label: 'World', name: 'IconMarketingSymWorld' },
    { label: 'Zoom In', name: 'IconMarketingSymZoomIn' },
    { label: 'Zoom Out', name: 'IconMarketingSymZoomOut' },
  ],
  travelAndTransportation: [
    { label: 'Air Travel', name: 'IconMarketingTraAirTravel' },
    { label: 'Bus/Shuttle', name: 'IconMarketingTraBusShuttle' },
    { label: 'Car', name: 'IconMarketingTraCar' },
    { label: 'Government', name: 'IconMarketingTraGovernment' },
    { label: 'Ground Travel', name: 'IconMarketingTraGroundTravel' },
    { label: 'Hospitality', name: 'IconMarketingTraHospitality' },
    { label: 'Office', name: 'IconMarketingTraHotel' },
    { label: 'Inclusion', name: 'IconMarketingTraInclusion' },
    { label: 'Oil / Gas', name: 'IconMarketingTraOilGas' },
    {
      label: 'Overcoming Challenges',
      name: 'IconMarketingTraOvercomingChallenges',
    },
    { label: 'People and culture', name: 'IconMarketingTraPeopleAndCulture' },
  ],
};
