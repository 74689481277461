import React, { ReactElement } from 'react';
import { Modal } from '../../../../components';
import { CommonIconEditIconModalIconPicker } from './components/CommonIconEditModalIconPicker';
import { CommonIconEditModalProps } from './container';
import { ConnectedCommonIconEditModalButtons } from './components/CommonIconEditModalButtons/container';

export const CommonIconEditModal = ({
  tabOrder,
  availableIcons,
  existingIcon,
  title,
  nodeUid,
  display,
  commonIconNodeTypeToEdit,
  setCommonIconNodeTypeToEdit,
  nodeType,
}: CommonIconEditModalProps): ReactElement => {
  if (commonIconNodeTypeToEdit !== nodeType) return <></>;

  return (
    <Modal
      onClose={() => setCommonIconNodeTypeToEdit(undefined)}
      show={true}
      title={title}
    >
      <form>
        <CommonIconEditIconModalIconPicker
          tabOrder={tabOrder}
          availableIcons={availableIcons}
          existingIcon={existingIcon}
        />
        <ConnectedCommonIconEditModalButtons
          existingIcon={existingIcon}
          nodeUid={nodeUid}
          display={display}
        />
      </form>
    </Modal>
  );
};
