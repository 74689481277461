import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setLeftPanelDeviceUid,
  setLeftPanelType,
} from '../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../redux/store';
import {
  diagramSelectors,
  setDeviceIdToConfigureInDiagram,
  setSelectedNodeToDelete,
} from '../../../../redux/diagram/slice';
import {
  logicalConnectionsSelectors,
  setIsDrawingLogicalConnectionFrom,
} from '../../../../redux/logicalConnections/slice';
import { HardwareNode } from '.';

const mapDispatchToProps = {
  setDeviceIdToConfigureInDiagram,
  setIsDrawingLogicalConnectionFrom,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  setSelectedNodeToDelete,
};

const mapStateToProps = (state: RootState) => ({
  expired: sessionViewSelectors.getExpired(state),
  isAddingLogicalConnection:
    diagramSelectors.getIsAddingLogicalConnection(state),
  isDrawingLogicalConnectionFrom:
    logicalConnectionsSelectors.getIsDrawingLogicalConnectionFrom(state),
  isSnapToGridEnabled: diagramSelectors.getIsSnapToGridEnabled(state),
});

interface HardwareNodeAssetProps extends BaseNodeProps {
  asset?: Hardware;
  iconName?: string;
}

export type HardwareNodeProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  HardwareNodeAssetProps;

export const HardwareNodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HardwareNode);
