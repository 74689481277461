import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { iconPickerSelectors } from '../../../../redux/iconPicker/slice';
import { IconPickerTabs } from '.';

export type IconPickerTabsProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  tabOrder: iconPickerSelectors.getTabOrder(state),
});

export const IconPickerTabsContainer = connect(mapStateToProps)(IconPickerTabs);
