import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { x, y } from '../../../d3';
import { UseConnection } from '../../Connections/hooks/use-connection';
import { calculateNewX1andY1, resetIsDraggingAttribute } from '../../../utils';
import { updateNodeHelper } from '../utils/utils';
import {
  NODE_IS_DRAGGING_VALUES,
  NODE_IS_DRAGGING_KEY,
} from '../../../constants';
import { determineIfNodeDragIsAllowed } from '../../utils/utils';

interface UseDrag extends BaseNodeProps {
  nodeRef: RefObject<HTMLDivElement>;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
  defaultPosition: DiagramDisplay;
}

export function useDrag({
  defaultPosition,
  display,
  isReadOnly,
  nodeRef,
  redrawNodeConnections,
  uid,
  isSnapToGridEnabled = true,
}: UseDrag): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLDivElement, DiagramDisplay>()
      .subject(function (_, d) {
        const transform = d3.zoomTransform(this);

        return {
          x: transform.rescaleX(x)(
            d?.x1 !== undefined ? d?.x1 : defaultPosition.x1!,
          ),
          y: transform.rescaleY(y)(
            d?.y1 !== undefined ? d?.y1 : defaultPosition.y1!,
          ),
        };
      })
      .on('start', function () {
        resetIsDraggingAttribute(d3.select(this));
      })
      .on('drag', function (event) {
        if (
          determineIfNodeDragIsAllowed({
            actionButtonsElement: d3
              .select<HTMLDivElement, unknown>(`#actionButtonsWrapper-${uid}`)
              .node(),
            clickedElement: event.sourceEvent.target,
          })
        ) {
          const component = d3.select(this);
          const transform = d3.zoomTransform(this);

          component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.true);
          component.attr(
            'style',
            `transform: translate(${event.x}px, ${event.y}px) scale(${transform.k}); z-index: 1`,
          );

          redrawNodeConnections && redrawNodeConnections(uid);
        }
      })
      .on('end', function (event) {
        const transform = d3.zoomTransform(this);
        const component = d3.select(this);

        const { x1, y1 } = calculateNewX1andY1({
          display,
          event,
          isSnapToGridEnabled,
          transform,
          x,
          y,
        });

        updateNodeHelper({
          component,
          display,
          uid,
          updateNode,
          x1,
          y1,
        });

        redrawNodeConnections && redrawNodeConnections(uid);

        if (currentRef) {
          currentRef.style.zIndex = '1';
        }
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .datum(display || ({} as DiagramDisplay))
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef).on(
          'mousedown.drag',
          null,
        );
      }
    };
  }, [
    defaultPosition.x1,
    defaultPosition.y1,
    display,
    isReadOnly,
    isSnapToGridEnabled,
    nodeRef,
    redrawNodeConnections,
    uid,
    updateNode,
  ]);
}
