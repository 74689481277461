import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../components/FormUnsavedChangesPrompt';
import { PageHeading } from '../../../../../../components/PageHeading';
import { useLeaveFormPrompt } from '../../../../hooks/useLeaveFormPrompt';
import { TEMP_UID_KEY } from '../constants';
import { HardwareProps } from '../container';
import { DeleteNetworkModal } from '../DeleteNetworkModal';
import { FormScriptsContainer } from '../FormScripts/container';
import { FormTemplatesContainer } from '../FormTemplate/container';
import styles from '../Edit.module.css';
import { Modal } from '../../../../../../components';
import { editValidationSchema } from './form-validation';
import { FormNetworking } from './FormNetworking';
import { createTemporaryNetwork, returnFieldOrUndefined } from './utils';
import { FormGeneralContainer } from './FormGeneralContainer';

export type FormProps = Omit<
  HardwareProps,
  'deviceIdToConfigureInDiagram' | 'onModalClose'
>;

export function Form({
  allHardware,
  hardwareItem,
  isUpdating,
  networkInterfaceToDelete,
  nodeData,
  setNetworkInterfaceToDelete,
  updateHardwareItem,
  updateNode,
  isModal,
  setDeviceIdToConfigureInDiagram,
}: FormProps): ReactElement {
  const { t } = useTranslation();
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const formMethods = useForm<EditHardwareFields>({
    defaultValues: {
      hardwareConsoleEnabled: hardwareItem.hardwareConsoleEnabled || false,
      hardwareNetworkInterfaces: hardwareItem.hardwareNetworkInterfaces || [],
      hiddenFromSession: nodeData?.display?.hidden || false,
      icon: nodeData?.display?.icon || '',
      inventoryCustomScript: hardwareItem.inventoryCustomScript || {
        name: '',
        uid: '',
      },
      inventoryShutdownScript: hardwareItem.inventoryShutdownScript || {
        name: '',
        uid: '',
      },
      inventoryStartupScript: hardwareItem.inventoryStartupScript || {
        name: '',
        uid: '',
      },
      inventoryTemplateConfigScript:
        hardwareItem.inventoryTemplateConfigScript || {
          name: '',
          uid: '',
        },
      name: hardwareItem.name || '',
      powerControlEnabled: hardwareItem.powerControlEnabled || false,
    },
    mode: 'all',
    resolver: yupResolver(
      editValidationSchema(
        allHardware
          .filter((item) => item.uid !== hardwareItem.uid)
          .map((item) => item.name),
      ),
    ),
  });

  const [networkInterfaces, setNetworkInterfaces] = useState(
    hardwareItem.hardwareNetworkInterfaces,
  );

  useEffect(() => {
    if (hardwareItem.hardwareNetworkInterfaces !== networkInterfaces) {
      setNetworkInterfaces(hardwareItem.hardwareNetworkInterfaces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hardwareItem.hardwareNetworkInterfaces]);

  const { dirtyFields, isValid } = formMethods.formState;
  const isDirty = !!Object.keys(dirtyFields).length;

  const areEqual = isEqual(
    hardwareItem?.hardwareNetworkInterfaces,
    networkInterfaces,
  );

  const isUnchanged = (!isDirty || !isValid) && areEqual;

  const { showModal, handleConfirm, handleCancel } = useLeaveFormPrompt(
    !isUnchanged,
    isClosing,
    () => {
      setDeviceIdToConfigureInDiagram(undefined);
      setIsClosing(false);
    },
    () => {
      setIsClosing(false);
    },
  );

  const onConfirmDelete = useCallback(() => {
    setNetworkInterfaces((prevState) =>
      prevState.filter((item) => item.uid !== networkInterfaceToDelete),
    );

    setNetworkInterfaceToDelete('');
  }, [networkInterfaceToDelete, setNetworkInterfaceToDelete]);

  const addNewNetworkInterface = (networkInterfaceFields: AddNetworkFields) => {
    setNetworkInterfaces((prevState) => [
      ...prevState,
      createTemporaryNetwork(networkInterfaceFields),
    ]);
  };

  const updateHardware = async (fields: EditHardwareFields): Promise<void> => {
    if (!hardwareItem.inventoryHardwareItem.hardwareConsoleAvailable) {
      delete fields.hardwareConsoleEnabled;
    }

    if (!hardwareItem.inventoryHardwareItem.powerControlAvailable) {
      delete fields.powerControlEnabled;
    }

    const payload: UpdateHardwarePayload = {
      ...fields,
      hardwareNetworkInterfaces: fields.hardwareNetworkInterfaces.map(
        (item) => {
          if (item.uid.includes(TEMP_UID_KEY)) {
            return {
              network: { uid: item.network.uid },
              networkInterface: { id: item.networkInterface.id },
            };
          }

          return item;
        },
      ),
      inventoryCustomScript: returnFieldOrUndefined(
        fields.inventoryCustomScript,
      ),
      inventoryHardwareItem: hardwareItem.inventoryHardwareItem,
      inventoryShutdownScript: returnFieldOrUndefined(
        fields.inventoryShutdownScript,
      ),
      inventoryStartupScript: returnFieldOrUndefined(
        fields.inventoryStartupScript,
      ),
      inventoryTemplateConfigScript: returnFieldOrUndefined(
        fields.inventoryTemplateConfigScript,
      ),
      name: fields.name,
      topology: hardwareItem.topology,
      uid: hardwareItem.uid,
    };

    const { dirtyFields } = formMethods.formState;
    if (dirtyFields.hiddenFromSession) {
      await updateNode({
        display: {
          ...nodeData?.display,
          hidden: fields.hiddenFromSession,
        },
        nodeUid: nodeData?.uid || '',
      })
        .unwrap()
        .catch(() => {
          return;
        });
    }

    if (dirtyFields.icon) {
      await updateNode({
        display: {
          ...nodeData?.display,
          icon: fields.icon,
        },
        nodeUid: nodeData?.uid || '',
      })
        .unwrap()
        .catch(() => {
          return;
        });
    }

    await updateHardwareItem({ ...payload, isModal })
      .unwrap()
      .then(() => resetForm(fields));
  };

  const onSubmit = (values: EditHardwareFields) => {
    updateHardware({ ...values, hardwareNetworkInterfaces: networkInterfaces });
  };

  const resetForm = (newHardware: EditHardwareFields) => {
    formMethods.reset(newHardware);
    setNetworkInterfaces(newHardware.hardwareNetworkInterfaces);
    setDeviceIdToConfigureInDiagram(undefined);
  };

  const onDeviceEditModalClose = () => {
    if (isUnchanged) {
      setDeviceIdToConfigureInDiagram(undefined);
    } else {
      setIsClosing(true);
    }
  };

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: (
          <FormGeneralContainer
            nodeData={nodeData}
            hardware={hardwareItem}
            isModal={isModal}
          />
        ),
        title: t('hardware.edit.titles.general'),
      },
      {
        children: <FormScriptsContainer />,
        title: t('hardware.edit.titles.scripts'),
      },
      {
        children: <FormTemplatesContainer />,
        title: t('hardware.edit.titles.templates'),
      },
      {
        children: (
          <FormNetworking
            hardware={hardwareItem}
            networkInterfaces={networkInterfaces}
            addNetworkInterface={addNewNetworkInterface}
            onDeleteClick={setNetworkInterfaceToDelete}
            onConfirmDelete={onConfirmDelete}
          />
        ),
        title: t('hardware.edit.titles.networking'),
      },
    ],
    [
      hardwareItem,
      t,
      nodeData,
      networkInterfaces,
      setNetworkInterfaceToDelete,
      onConfirmDelete,
      isModal,
    ],
  );
  const renderForm = () => (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <PageHeading
          withBackBtn={true}
          pageTitle={t('hardware.physicalHardware.edit.title')}
          ctaBtn={
            <PrimaryButton
              type="submit"
              colour="success"
              size="large"
              loading={isUpdating}
              disabled={isUnchanged}
              testId="edit-hardware-update"
            >
              {t('buttons.update')}
            </PrimaryButton>
          }
        />
        <Accordion rows={accordionRows} collapsed={isModal} />
      </form>
    </FormProvider>
  );
  return (
    <>
      {isModal ? (
        <Modal
          show={true}
          onClose={onDeviceEditModalClose}
          size="fluid"
          clickScreenToClose={true}
          className={styles.diagramDeviceEditModal}
        >
          {renderForm()}
        </Modal>
      ) : (
        renderForm()
      )}
      {networkInterfaceToDelete && (
        <DeleteNetworkModal
          onClose={() => setNetworkInterfaceToDelete('')}
          onConfirm={onConfirmDelete}
          show={!!networkInterfaceToDelete}
        />
      )}
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}
