import { D3DragEvent } from 'd3';
import * as d3 from 'd3';
import { NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES } from './constants';

export const isNodeHidden = (node: DiagramNode): boolean => {
  return node.display?.hidden === true;
};
export const isInvisibleNetwork = (node: DiagramNode): boolean => {
  return node.display?.hidden === true && node.assetType === 'NETWORK';
};

export const isConnectionHidden = (
  connection: DiagramConnection,
  nodes: DiagramNodes,
): boolean =>
  nodes.find(
    (node) =>
      isNodeHidden(node) &&
      (connection.nodes.to.uid === node.uid ||
        connection.nodes.from.uid === node.uid),
  ) !== undefined;

export const isConnectionInvisible = (
  connection: DiagramConnection,
  nodes: DiagramNodes,
): boolean =>
  nodes.find(
    (node) =>
      isInvisibleNetwork(node) &&
      (connection.nodes.to.uid === node.uid ||
        connection.nodes.from.uid === node.uid),
  ) !== undefined;

export const retrieveVisibleNodes = (nodes: DiagramNodes): DiagramNodes =>
  nodes.filter((node: DiagramNode) => !isNodeHidden(node));

export const retrieveVisibleConnections = (
  connections: DiagramConnection[],
  nodes: DiagramNodes,
): DiagramConnection[] =>
  connections.filter((connection) => !isConnectionHidden(connection, nodes));

export const setHiddenConnections = (
  connections: DiagramConnection[],
  nodes: DiagramNodes,
): DiagramConnection[] =>
  connections.map((connection) => {
    const isHidden = isConnectionHidden(connection, nodes);
    const isInvisible = isConnectionInvisible(connection, nodes);
    return {
      ...connection,
      display: {
        ...connection.display,
        hidden: isHidden,
        invisible: isInvisible,
      },
    };
  });

export type CalculateNewCoordinate = {
  isSnapToGridEnabled?: boolean;
  isUserOverridingSnapToGrid?: boolean;
  coordinate: number;
};

export const calculateNewCoordinate = ({
  isUserOverridingSnapToGrid = false,
  isSnapToGridEnabled,
  coordinate,
}: CalculateNewCoordinate): number => {
  if (!isSnapToGridEnabled || isUserOverridingSnapToGrid) return coordinate;

  return roundCoord(coordinate);
};

export const roundCoord = (coord: number): number => Math.round(coord);

export const calculateAbsDifferenceBetweenTwoNumbers = (
  num1: number,
  num2: number,
): number => Math.abs(num1 - num2);

export const isAbsDifferenceEqualToOrGreaterThan1 = (
  num1: number,
  num2: number,
): boolean => {
  const diff = calculateAbsDifferenceBetweenTwoNumbers(num1, num2);

  return diff >= 1;
};

type IsUpdateIconNodeRequired = {
  x?: number;
  y?: number;
  invertedX: number;
  invertedY: number;
  isSnapToGridEnabled?: boolean;
  isUserOverridingSnapToGrid: boolean;
};

export const isUpdateToNodesCoordsRequired = ({
  x,
  y,
  invertedX,
  invertedY,
  isSnapToGridEnabled,
  isUserOverridingSnapToGrid,
}: IsUpdateIconNodeRequired): boolean => {
  if (
    isSnapToGridEnabled === false ||
    isEitherXorYUndefined(x, y) ||
    isSnapToGridBeingOverriden(isSnapToGridEnabled, isUserOverridingSnapToGrid)
  )
    return true;

  if (
    isSnapToGridEnabled &&
    isMoveGreaterThanOrEqualTo1({
      invertedX,
      invertedY,
      x,
      y,
    })
  ) {
    return true;
  }

  return false;
};

export const isEitherXorYUndefined = (
  x: number | undefined,
  y: number | undefined,
): boolean => x === undefined || y === undefined;

export const isSnapToGridBeingOverriden = (
  isSnapToGridEnabled: boolean | undefined,
  isUserOverridingSnapToGrid: boolean,
): boolean => !!isSnapToGridEnabled && isUserOverridingSnapToGrid;

export type IsMoveGreaterThanOrEqualTo1 = {
  x?: number;
  y?: number;
  invertedX: number;
  invertedY: number;
};

export const isMoveGreaterThanOrEqualTo1 = ({
  x,
  y,
  invertedX,
  invertedY,
}: IsMoveGreaterThanOrEqualTo1): boolean => {
  return (
    isAbsDifferenceEqualToOrGreaterThan1(invertedX, x as number) ||
    isAbsDifferenceEqualToOrGreaterThan1(invertedY, y as number)
  );
};

type CalculateNewX1andY1 = {
  isSnapToGridEnabled: boolean;
  x: d3.ScaleLinear<number, number, never>;
  y: d3.ScaleLinear<number, number, never>;
  display?: DiagramDisplay;
  event: D3DragEvent<HTMLDivElement, DiagramDisplay, unknown>;
  transform: d3.ZoomTransform;
};

export const calculateNewX1andY1 = ({
  event,
  isSnapToGridEnabled,
  x,
  y,
  display,
  transform,
}: CalculateNewX1andY1): { x1: number; y1: number } => {
  const invertedX = x.invert(transform.invertX(event.x));
  const invertedY = y.invert(transform.invertY(event.y));
  const isUserOverridingSnapToGrid = event.sourceEvent.altKey;

  let x1 = display?.x1;
  let y1 = display?.y1;

  if (
    isUpdateToNodesCoordsRequired({
      invertedX,
      invertedY,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
      x: display?.x1,
      y: display?.y1,
    })
  ) {
    x1 = calculateNewCoordinate({
      coordinate: invertedX,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });

    y1 = calculateNewCoordinate({
      coordinate: invertedY,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });
  }

  return { x1: x1!, y1: y1! };
};

type HandleOnTextAndBoxNodeDrag = {
  isSnapToGridEnabled: boolean;
  x: d3.ScaleLinear<number, number, never>;
  y: d3.ScaleLinear<number, number, never>;
  display: DiagramTextDisplay;
  event: D3DragEvent<HTMLDivElement, DiagramDisplay, unknown>;
  transform: d3.ZoomTransform;
};

export const calculateNewXandY = ({
  event,
  isSnapToGridEnabled,
  x,
  y,
  display,
  transform,
}: HandleOnTextAndBoxNodeDrag): { x: number; y: number } => {
  const invertedX = x.invert(transform.invertX(event.x));
  const invertedY = y.invert(transform.invertY(event.y));
  const isUserOverridingSnapToGrid = event.sourceEvent.altKey;

  let newX = display?.x;
  let newY = display?.y;

  if (
    isUpdateToNodesCoordsRequired({
      invertedX,
      invertedY,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
      x: display?.x,
      y: display?.y,
    })
  ) {
    newX = calculateNewCoordinate({
      coordinate: invertedX,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });

    newY = calculateNewCoordinate({
      coordinate: invertedY,
      isSnapToGridEnabled,
      isUserOverridingSnapToGrid,
    });
  }

  return { x: newX, y: newY };
};

type UpdateNodeDisplayHelper = {
  updateNode: RTKMutation<UpdateDiagramNodeProps, UpdateDiagramNodeProps>;
  display: DiagramDisplay;
  nodeUid: string;
  component: d3.Selection<HTMLDivElement, unknown, null, undefined>;
};

export const updateNodeDisplayHelper = ({
  component,
  updateNode,
  display,
  nodeUid,
}: UpdateNodeDisplayHelper): void => {
  if (component.attr(NODE_IS_DRAGGING_KEY) === NODE_IS_DRAGGING_VALUES.true) {
    updateNode({
      display,
      nodeUid,
    });
    component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.false);
  }
};

export const resetIsDraggingAttribute = (
  component: d3.Selection<HTMLDivElement, unknown, null, undefined>,
): void => {
  if (component.attr(NODE_IS_DRAGGING_KEY) !== NODE_IS_DRAGGING_VALUES.false) {
    component.attr(NODE_IS_DRAGGING_KEY, NODE_IS_DRAGGING_VALUES.false);
  }
};
