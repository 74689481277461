/* eslint-disable import/namespace */
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import * as IconPickerIcons from '../../../../assets/images/icons';
import styles from './index.module.css';
import { IconPickerIconProps } from './container';

export function IconPickerIcon({
  icon,
  isSelected,
  setSelectedIcon,
}: IconPickerIconProps): ReactElement {
  const Component = IconPickerIcons[icon.name as keyof typeof IconPickerIcons];

  if (!Component) return <></>;

  const handleOnClick = () => {
    setSelectedIcon(icon.name);
  };

  return (
    <div
      role="button"
      aria-label={`Select ${icon.label} Icon`}
      onClick={handleOnClick}
      className={classNames(styles.root, {
        [styles.isIconPickerIconSelected]: isSelected,
      })}
    >
      <div className={styles.iconImage}>
        <Component title={icon.label} />
      </div>
      <div className={styles.iconLabel}>
        <p>{icon.label}</p>
      </div>
    </div>
  );
}
