import React, { ReactElement } from 'react';
import { NodeActionButtons } from '../../Diagram/components/NodeActionButtons';
import { CommonIconEditButtonProps } from './container';

export const CommonIconEditButton = ({
  name,
  isAddingLogicalConnection,
  isReadOnly,
  setCommonIconNodeTypeToEdit,
}: CommonIconEditButtonProps): ReactElement => {
  if (isReadOnly || isAddingLogicalConnection) return <></>;

  const handleOnEditClick = () => {
    setCommonIconNodeTypeToEdit(name);
  };

  return (
    <NodeActionButtons onEditClick={handleOnEditClick} name={name || ''} />
  );
};
