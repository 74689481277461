import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DiagramDrawingColorName } from '../../typings/drawing-palettes';
import { resetLogicalConnections } from '../logicalConnections/slice';

interface DiagramState {
  isAddingText: boolean;
  deviceIdToConfigureInDiagram: undefined | string;
  networkToConfigureInDiagram: undefined | Network;
  selectedStrokeColorName: DiagramDrawingColorName;
  selectedStrokeType: DiagramDrawingStrokeType;
  showAddNetworkMaxLimitsReachedModal: boolean;
  showAddNetworkLoadingModal: boolean;
  showAddHardwareSavingModal: boolean;
  showAddIconModal: boolean;
  selectedIcon?: string;
  selectedIconToEdit?: DiagramIconToEditActionPayload;
  selectedIconToDelete?: DiagramIconToDeleteActionPayload;
  selectedNodeToDelete?: SelectedNodeToDelete;
  showAddLogicalConnectionLimitModal: boolean;
  showAddHardwareModal: boolean;
  showAddHardwareLoader: boolean;
  showAddVmSavingModal: boolean;
  showAddVmModal: boolean;
  showAddVmLoader: boolean;
  commonIconNodeTypeToEdit?: CommonDiagramIcons;
  isAddingBox: boolean;
  isAddingLine: boolean;
  isAddingLogicalConnection: boolean;
  isDiagramProcessing: boolean;
  isSnapToGridEnabled?: boolean;
  textNodeToConfigureInDiagram?: TextNodeToConfigureInDiagram;
  vmIdToConfigureInDiagram: undefined | string;
}

export const initialState: DiagramState = {
  commonIconNodeTypeToEdit: undefined,
  deviceIdToConfigureInDiagram: undefined,
  isAddingBox: false,
  isAddingLine: false,
  isAddingLogicalConnection: false,
  isAddingText: false,
  isDiagramProcessing: false,
  isSnapToGridEnabled: undefined,
  networkToConfigureInDiagram: undefined,
  selectedIcon: undefined,
  selectedIconToDelete: undefined,
  selectedIconToEdit: undefined,
  selectedNodeToDelete: undefined,
  selectedStrokeColorName: undefined,
  selectedStrokeType: undefined,
  showAddHardwareLoader: false,
  showAddHardwareModal: false,
  showAddHardwareSavingModal: false,
  showAddIconModal: false,
  showAddLogicalConnectionLimitModal: false,
  showAddNetworkLoadingModal: false,
  showAddNetworkMaxLimitsReachedModal: false,
  showAddVmLoader: false,
  showAddVmModal: false,
  showAddVmSavingModal: false,
  textNodeToConfigureInDiagram: undefined,
  vmIdToConfigureInDiagram: undefined,
};

export const updateNode =
  createAction<UpdateDiagramNodeProps>('diagram/updateNode');

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(resetLogicalConnections, (state) => {
      state.isAddingLogicalConnection = initialState.isAddingLogicalConnection;
    });
  },
  initialState,
  name: 'diagram',
  reducers: {
    resetAddIcon: (state) => {
      state.showAddIconModal = initialState.showAddIconModal;
      state.selectedIcon = initialState.selectedIcon;
    },
    resetEditIcon: (state) => {
      state.selectedIcon = initialState.selectedIcon;
      state.selectedIconToEdit = initialState.selectedIconToEdit;
    },
    setCommonIconNodeTypeToEdit: (
      state,
      action: PayloadAction<CommonDiagramIcons | undefined>,
    ) => {
      state.commonIconNodeTypeToEdit = action.payload;
    },
    setDeviceIdToConfigureInDiagram: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.deviceIdToConfigureInDiagram = action.payload;
    },
    setIsAddingBox: (state, action: PayloadAction<boolean>) => {
      state.isAddingBox = action.payload;
    },
    setIsAddingLine: (state, action: PayloadAction<boolean>) => {
      state.isAddingLine = action.payload;
    },
    setIsAddingLogicalConnection: (state, action: PayloadAction<boolean>) => {
      state.isAddingLogicalConnection = action.payload;
    },
    setIsAddingText: (state, action: PayloadAction<boolean>) => {
      state.isAddingText = action.payload;
    },
    setIsDiagramProcessing: (state, action: PayloadAction<boolean>) => {
      state.isDiagramProcessing = action.payload;
    },
    setIsSnapToGridEnabled: (state, action: PayloadAction<boolean>) => {
      state.isSnapToGridEnabled = action.payload;
    },
    setNetworkToConfigureInDiagram: (
      state,
      action: PayloadAction<Network | undefined>,
    ) => {
      state.networkToConfigureInDiagram = action.payload;
    },
    setSelectedIcon: (state, action: PayloadAction<string | undefined>) => {
      state.selectedIcon = action.payload;
    },
    setSelectedIconToDelete: (
      state,
      action: PayloadAction<DiagramIconToDeleteActionPayload | undefined>,
    ) => {
      state.selectedIconToDelete = action.payload;
    },
    setSelectedIconToEdit: (
      state,
      action: PayloadAction<DiagramIconToEditActionPayload | undefined>,
    ) => {
      state.selectedIconToEdit = action.payload;
    },
    setSelectedNodeToDelete: (
      state,
      action: PayloadAction<SelectedNodeToDelete | undefined>,
    ) => {
      state.selectedNodeToDelete = action.payload;
    },
    setSelectedStrokeColorName: (
      state,
      action: PayloadAction<DiagramDrawingColorName>,
    ) => {
      state.selectedStrokeColorName = action.payload;
    },
    setSelectedStrokeType: (
      state,
      action: PayloadAction<DiagramDrawingStrokeType>,
    ) => {
      state.selectedStrokeType = action.payload;
    },
    setShowAddHardwareLoader: (state, action: PayloadAction<boolean>) => {
      state.showAddHardwareLoader = action.payload;
    },
    setShowAddHardwareModal: (state, action: PayloadAction<boolean>) => {
      state.showAddHardwareModal = action.payload;
    },
    setShowAddHardwareSavingModal: (state, action: PayloadAction<boolean>) => {
      state.showAddHardwareSavingModal = action.payload;
    },
    setShowAddIconModal: (state, action: PayloadAction<boolean>) => {
      state.showAddIconModal = action.payload;
    },
    setShowAddLogicalConnectionLimitModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showAddLogicalConnectionLimitModal = action.payload;
    },
    setShowAddNetworkLoadingModal: (state, action: PayloadAction<boolean>) => {
      state.showAddNetworkLoadingModal = action.payload;
    },
    setShowAddNetworkMaxLimitsReachedModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showAddNetworkMaxLimitsReachedModal = action.payload;
    },
    setShowAddVmLoader: (state, action: PayloadAction<boolean>) => {
      state.showAddVmLoader = action.payload;
    },
    setShowAddVmModal: (state, action: PayloadAction<boolean>) => {
      state.showAddVmModal = action.payload;
    },
    setShowAddVmSavingModal: (state, action: PayloadAction<boolean>) => {
      state.showAddVmSavingModal = action.payload;
    },
    setTextNodeToConfigureInDiagram: (
      state,
      action: PayloadAction<TextNodeToConfigureInDiagram | undefined>,
    ) => {
      state.textNodeToConfigureInDiagram = action.payload;
    },
    setVmIdToConfigureInDiagram: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.vmIdToConfigureInDiagram = action.payload;
    },
  },
});

export const handleAddHardwareFromDiagram =
  createAction<InventoryHardwareItemsToAddFromDiagram>(
    'diagram/handleAddHardwareFromDiagram',
  );

export const handleAddVmFromTemplatesTabFromDiagramWorker =
  createAction<VirtualMachineToAddFromTemplatesTabFromDiagram>(
    'diagram/handleAddVmFromTemplatesTabFromDiagramWorker',
  );

export const handleAddVmFromDemosTabFromDiagram =
  createAction<VirtualMachineToAddFromDemosTabFromDiagram>(
    'diagram/handleAddVmFromDemosTabFromDiagram',
  );

export const getDiagram = (state: RootState): DiagramState => state[slice.name];

export const { reducer: diagramReducer } = slice;
export const {
  resetAddIcon,
  resetEditIcon,
  setIsAddingBox,
  setIsAddingLine,
  setIsAddingLogicalConnection,
  setIsAddingText,
  setIsDiagramProcessing,
  setIsSnapToGridEnabled,
  setCommonIconNodeTypeToEdit,
  setDeviceIdToConfigureInDiagram,
  setSelectedStrokeColorName,
  setSelectedStrokeType,
  setShowAddLogicalConnectionLimitModal,
  setShowAddNetworkMaxLimitsReachedModal,
  setShowAddNetworkLoadingModal,
  setShowAddHardwareModal,
  setShowAddHardwareSavingModal,
  setShowAddIconModal,
  setSelectedIcon,
  setSelectedIconToEdit,
  setSelectedIconToDelete,
  setSelectedNodeToDelete,
  setShowAddHardwareLoader,
  setShowAddVmLoader,
  setShowAddVmModal,
  setShowAddVmSavingModal,
  setNetworkToConfigureInDiagram,
  setTextNodeToConfigureInDiagram,
  setVmIdToConfigureInDiagram,
} = slice.actions;

export const diagramSelectors = {
  getCommonIconNodeTypeToEdit: createSelector(
    getDiagram,
    ({ commonIconNodeTypeToEdit }) => commonIconNodeTypeToEdit,
  ),
  getDeviceIdToConfigureInDiagram: createSelector(
    getDiagram,
    ({ deviceIdToConfigureInDiagram }) => deviceIdToConfigureInDiagram,
  ),
  getIsAddingBox: createSelector(getDiagram, ({ isAddingBox }) => isAddingBox),
  getIsAddingLine: createSelector(
    getDiagram,
    ({ isAddingLine }) => isAddingLine,
  ),
  getIsAddingLogicalConnection: createSelector(
    getDiagram,
    ({ isAddingLogicalConnection }) => isAddingLogicalConnection,
  ),
  getIsAddingText: createSelector(
    getDiagram,
    ({ isAddingText }) => isAddingText,
  ),
  getIsDiagramProcessing: createSelector(
    getDiagram,
    ({ isDiagramProcessing }) => isDiagramProcessing,
  ),
  getIsSnapToGridEnabled: createSelector(
    getDiagram,
    ({ isSnapToGridEnabled }) => isSnapToGridEnabled,
  ),
  getNetworkToConfigureInDiagram: createSelector(
    getDiagram,
    ({ networkToConfigureInDiagram }) => networkToConfigureInDiagram,
  ),
  getSelectedIcon: createSelector(
    getDiagram,
    ({ selectedIcon }) => selectedIcon,
  ),
  getSelectedIconToDelete: createSelector(
    getDiagram,
    ({ selectedIconToDelete }) => selectedIconToDelete,
  ),
  getSelectedIconToEdit: createSelector(
    getDiagram,
    ({ selectedIconToEdit }) => selectedIconToEdit,
  ),
  getSelectedNodeToDelete: createSelector(
    getDiagram,
    ({ selectedNodeToDelete }) => selectedNodeToDelete,
  ),
  getSelectedStrokeColorName: createSelector(
    getDiagram,
    ({ selectedStrokeColorName }) => selectedStrokeColorName,
  ),
  getSelectedStrokeType: createSelector(
    getDiagram,
    ({ selectedStrokeType }) => selectedStrokeType,
  ),
  getShowAddHardwareLoader: createSelector(
    getDiagram,
    ({ showAddHardwareLoader }) => showAddHardwareLoader,
  ),
  getShowAddHardwareModal: createSelector(
    getDiagram,
    ({ showAddHardwareModal }) => showAddHardwareModal,
  ),
  getShowAddHardwareSavingModal: createSelector(
    getDiagram,
    ({ showAddHardwareSavingModal }) => showAddHardwareSavingModal,
  ),
  getShowAddIconModal: createSelector(
    getDiagram,
    ({ showAddIconModal }) => showAddIconModal,
  ),
  getShowAddLogicalConnectionLimitModal: createSelector(
    getDiagram,
    ({ showAddLogicalConnectionLimitModal }) =>
      showAddLogicalConnectionLimitModal,
  ),
  getShowAddNetworkLoadingModal: createSelector(
    getDiagram,
    ({ showAddNetworkLoadingModal }) => showAddNetworkLoadingModal,
  ),
  getShowAddVmLoader: createSelector(
    getDiagram,
    ({ showAddVmLoader }) => showAddVmLoader,
  ),
  getShowAddVmModal: createSelector(
    getDiagram,
    ({ showAddVmModal }) => showAddVmModal,
  ),
  getShowAddVmSavingModal: createSelector(
    getDiagram,
    ({ showAddVmSavingModal }) => showAddVmSavingModal,
  ),
  getShowMaxLimitsReachedModal: createSelector(
    getDiagram,
    ({ showAddNetworkMaxLimitsReachedModal }) =>
      showAddNetworkMaxLimitsReachedModal,
  ),
  getTextNodeToConfigureInDiagram: createSelector(
    getDiagram,
    ({ textNodeToConfigureInDiagram }) => textNodeToConfigureInDiagram,
  ),
  getVmIdToConfigureInDiagram: createSelector(
    getDiagram,
    ({ vmIdToConfigureInDiagram }) => vmIdToConfigureInDiagram,
  ),
};

export const getIsDiagramProcessing = (state: RootState): DiagramState =>
  state[slice.name];
