import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';
import { calculateLineAngleByEndpoints, x, y } from '../../../d3';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import {
  calculateNewXandY,
  updateNodeDisplayHelper,
  resetIsDraggingAttribute,
} from '../../../utils';
import {
  NODE_IS_DRAGGING_KEY,
  NODE_IS_DRAGGING_VALUES,
} from '../../../constants';
import { determineIfNodeDragIsAllowed } from '../../utils/utils';
import {
  calculateCenterPointsOfNode,
  determineIsRotating,
} from './utils/utils';

interface UseDrag {
  display: DiagramTextDisplay;
  uid: string;
  isReadOnly: boolean;
  nodeRef: RefObject<HTMLDivElement>;
  isSnapToGridEnabled?: boolean;
}

export function useDrag({
  display,
  isReadOnly = false,
  nodeRef,
  uid,
  isSnapToGridEnabled = true,
}: UseDrag): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;
    let isRotating = false;
    let { angle = 0 } = display;
    const drag = d3
      .drag<HTMLDivElement, DiagramTextDisplay>()
      .subject(function (_, d) {
        const transform = d3.zoomTransform(this);
        return {
          x: transform.rescaleX(x)(d.x),
          y: transform.rescaleY(y)(d.y),
        };
      })
      .on('start', function (event) {
        isRotating = determineIsRotating({
          rotateIconObject: document.querySelector(
            `[data-component="${uid}"] [title="Rotate Text"]`,
          ),
          target: event.sourceEvent.target,
        });
        resetIsDraggingAttribute(d3.select(this));
      })
      .on('drag', function (event) {
        if (
          determineIfNodeDragIsAllowed({
            actionButtonsElement: d3
              .select<HTMLDivElement, unknown>(`#actionButtonsWrapper-${uid}`)
              .node(),
            clickedElement: event.sourceEvent.target,
          })
        ) {
          if (isRotating) {
            const nodeCoordinates = calculateCenterPointsOfNode(uid);
            angle = calculateLineAngleByEndpoints(
              event.sourceEvent.clientY,
              nodeCoordinates[0],
              nodeCoordinates[1],
              event.sourceEvent.clientX,
            );
            const component = d3.select(
              document.querySelector(`[data-component-text="${uid}"]`),
            );
            component.attr('style', `transform: rotate(${angle}deg)`);
          } else {
            const component = d3.select(this);
            const transform = d3.zoomTransform(this);
            component.attr(
              'style',
              `transform: translate(${event.x}px, ${event.y}px) scale(${transform.k})`,
            );
          }
          d3.select(this).attr(
            NODE_IS_DRAGGING_KEY,
            NODE_IS_DRAGGING_VALUES.true,
          );
        }
      })
      .on('end', function (event) {
        const component = d3.select(this);
        if (isRotating) {
          updateNodeDisplayHelper({
            component,
            display: { ...display, angle },
            nodeUid: uid,
            updateNode,
          });
        } else {
          const transform = d3.zoomTransform(this);
          const { x: newX, y: newY } = calculateNewXandY({
            display,
            event,
            isSnapToGridEnabled,
            transform,
            x,
            y,
          });
          updateNodeDisplayHelper({
            component,
            display: { ...display, x: newX, y: newY },
            nodeUid: uid,
            updateNode,
          });
        }
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramTextDisplay>(currentRef)
        .datum(display || ({} as DiagramTextDisplay))
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramTextDisplay>(currentRef).on(
          'mousedown.drag',
          null,
        );
      }
    };
  }, [display, isReadOnly, nodeRef, updateNode, uid, isSnapToGridEnabled]);
}
