import React, { ReactElement } from 'react';
import { IconPickerIconContainer } from '../IconPickerIcon/container';
import { IconPickerIconsProps } from './container';

export const IconPickerIcons = ({
  icons,
}: IconPickerIconsProps): ReactElement => {
  if (!icons) return <></>;

  return (
    <>
      {icons.map((diagramIcon) => (
        <IconPickerIconContainer
          key={`${diagramIcon.name}`}
          icon={diagramIcon}
        />
      ))}
    </>
  );
};
