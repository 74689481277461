import React, { ReactElement } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { Icon } from 'dcloud-shared-ui';
import styles from '../../DiagramTextNode.module.css';

export type DiagramTextNodeEditButtonProps = {
  isReadOnly: boolean;
  isAddingLogicalConnection: boolean;
  setTextNodeToConfigureInDiagram: ActionCreatorWithOptionalPayload<
    TextNodeToConfigureInDiagram | undefined,
    string
  >;
  display: DiagramTextDisplay;
  uid: string;
};

export const DiagramTextNodeEditButton = ({
  isReadOnly,
  isAddingLogicalConnection,
  setTextNodeToConfigureInDiagram,
  display,
  uid,
}: DiagramTextNodeEditButtonProps): ReactElement => {
  if (isReadOnly || isAddingLogicalConnection) return <></>;

  return (
    <div
      className={styles.editIconWrapper}
      onClick={() => setTextNodeToConfigureInDiagram({ display, uid })}
      data-testid="edit-diagram-text"
      id={`actionButtonsWrapper-${uid}`}
    >
      <Icon name="pencil" />
    </div>
  );
};
