import React, { ReactElement } from 'react';
import { Tab, Tabs } from '../../../Tabs';
import { IconPickerTabContent } from '../IconPickerTabContent';
import { formatTabTitle } from './utils/utils';
import styles from './index.module.css';
import { IconPickerTabsProps } from './container';

export const IconPickerTabs = ({
  tabOrder = [],
}: IconPickerTabsProps): ReactElement => (
  <div className={styles.root}>
    <Tabs id="icon-picker-tabs">
      {tabOrder.map((tabOrderItem) => {
        return (
          <Tab
            id={`icon-picker-tabs-tab-${tabOrderItem}`}
            title={formatTabTitle(tabOrderItem)}
            key={tabOrderItem}
          >
            <IconPickerTabContent tabTitle={tabOrderItem} />
          </Tab>
        );
      })}
    </Tabs>
  </div>
);
