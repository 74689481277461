import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { iconPickerSelectors } from '../../../../../../../../redux/iconPicker/slice';
import { GeneralForm } from '.';

type GeneralFormContainerOwnProps = {
  operatingSystems: OperatingSystem[];
  nodeData?: DiagramNode;
};

const mapStateToProps = (
  state: RootState,
  ownProps: GeneralFormContainerOwnProps,
) => ({
  selectedIcon: iconPickerSelectors.getSelectedIcon(state),
  ...ownProps,
});

export type GeneralFormProps = ReturnType<typeof mapStateToProps>;

export const GeneralFormContainer = connect(mapStateToProps)(GeneralForm);
