/* eslint-disable import/namespace */
import React, { ReactElement } from 'react';
import * as IconPickerIcons from '../../../../../assets/images/icons';
import { ReactComponent as VPodGatewayImage } from '../images/VPodGateway.svg';

export type DetermineIconToRender = {
  display?: DiagramDisplay;
  uid: string;
};

export const determineIconToRender = ({
  display,
  uid,
}: DetermineIconToRender): ReactElement => {
  if (display?.icon === undefined || display?.icon === 'RouterIcon')
    return <VPodGatewayImage data-component={uid} />;

  const IconComponent =
    IconPickerIcons[display?.icon as keyof typeof IconPickerIcons];

  return <IconComponent data-component={uid} />;
};
